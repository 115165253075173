<template>
  <div class="section">
    <div class="container-default w-container">
      <div data-w-id="08e093bc-1319-eed6-7231-a28773ea5f94" class="text-center mg-bottom-32px">
        <div class="inner-container _636px center">
          <h2 class="display-2 mg-bottom-12px" :style="`color: var(--${vars.titleColor}) !important;`">
            <strong :style="`color: var(--${vars.titleColor}) !important;`">{{ vars.titleText }}</strong>
          </h2>
          <p v-for="(item, index) in descriptions" :key="index" :style="`color: var(--${vars.textColor}) !important;`">
            {{ item.description }}
          </p>
        </div>
      </div>
      <div
        data-current="Tab 1"
        data-easing="ease"
        data-duration-in="300"
        data-duration-out="100"
        class="flex-vertical center mg-bottom-56px w-tabs"
      >
        <div data-w-id="08e093bc-1319-eed6-7231-a28773ea5fa3" class="width-100 w-tab-content">
          <div data-w-tab="Tab 1" class="w-tab-pane w--tab-active">
            <div class="card tw-bg-transparent">
              <library-video-embed :video-url="vars.videoUrl" :title="vars.titleText" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'ScrollingLogos',

  mixins: [ComponentMixin],

  computed: {
    descriptions() {
      return this.groupedVariables.descriptions;
    },
  },
});
</script>
