<template>
  <div id="podcast-section" class="section overflow-hidden section-low-top margin-top-30px">
    <h2
      class="display-2 tw-mb-10 tw-pb-0 tw-text-center"
      style="color: var(--text_primary) !important"
      data-v-inspector="components/theme0/components/OurAwards.vue:6:11"
      data-v-4a6b58d0=""
    >
      <strong
        style="color: var(--text_primary) !important"
        data-v-inspector="components/theme0/components/OurAwards.vue:7:13"
        data-v-4a6b58d0=""
        >{{ vars.titleText }}
      </strong>
    </h2>
    <div class="container-default w-container">
      <div
        data-delay="4000"
        data-animation="slide"
        class="slider-wrapper slider-buttons-bottom-center w-slider"
        data-autoplay="false"
        data-easing="ease"
        data-hide-arrows="false"
        data-disable-swipe="false"
        data-w-id="ff753f2e-d8c2-b17f-f247-7b804da88720"
        data-autoplay-limit="0"
        data-nav-spacing="3"
        data-duration="500"
        data-infinite="true"
        fs-cmsslider-element="slider-4"
      >
        <div class="slider-mask width-492px w-slider-mask" id="w-slider-mask-0">
          <div aria-live="off" aria-atomic="true" class="w-slider-aria-label" data-wf-ignore=""></div>
          <div
            v-for="(card, index) in cards"
            :key="`cards-${index}`"
            class="slide-item-mg w-slide"
            aria-label="1 of 5"
            role="group"
            style="transition: all; transform: translateX(0px); opacity: 1"
          >
            <div class="w-dyn-item">
              <div class="card-link---image-wrapper border-radius-26px">
                <div style="padding-top: 56.20608899297424%" class="w-video w-embed">
                  <iframe
                    class="embedly-embed"
                    :src="card.video"
                    width="854"
                    height="480"
                    scrolling="no"
                    title="YouTube embed"
                    frameborder="0"
                    allow="autoplay; fullscreen; encrypted-media; picture-in-picture;"
                    allowfullscreen="true"
                  ></iframe>
                </div>
              </div>
              <div class="card blog-link-text-card v2 podcast-card min-h-222">
                <h3 class="card-link-title mg-bottom-0 podcast-title">{{ card.title }}</h3>
                <p class="text-ellipsis-3">
                  {{ card.description }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          class="slider-btn-dark left-btn-center w-slider-arrow-left"
          role="button"
          tabindex="0"
          aria-controls="w-slider-mask-0"
          aria-label="previous slide"
        >
          <div></div>
        </div>
        <div
          class="slider-btn-dark right-btn-center w-slider-arrow-right"
          role="button"
          tabindex="0"
          aria-controls="w-slider-mask-0"
          aria-label="next slide"
        >
          <div></div>
        </div>
        <div class="hidden-on-desktop w-slider-nav w-round"></div>
      </div>
      <div
        v-if="vars.buttons1LabelText"
        data-w-id="ddf51907-cdd4-05ef-2076-91b2db615056"
        class="buttons-row center tw-pt-10"
      >
        <div v-for="(button, index) in buttons" :key="`button-${index}`" class="max-md:tw-w-full">
          <nuxt-link
            :to="button.link"
            :external="true"
            class="w-button"
            :class="`${button.type} ${index === 0 ? 'button-row' : ''}`"
          >
            {{ button.label }}
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'VideoSlider',

  mixins: [ComponentMixin],

  computed: {
    cards() {
      return this.groupedVariables.cards;
    },

    buttons() {
      return this.groupedVariables.buttons;
    },
  },
});
</script>
