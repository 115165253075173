<template>
  <div id="eax-browse-academy" class="section bg-neutral-100 section-low-bottom">
    <div class="container-default w-container">
      <div
        data-w-id="abe4eef9-8242-5ef7-e38d-8b5c21cb1e9f"
        style="
          -webkit-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
          -moz-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
          -ms-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
          transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
        "
        class="text-center mg-bottom-40px"
      >
        <div class="inner-container _522px center">
          <h2 class="display-2 mg-bottom-8px" :style="`color: var(--${vars.titleColor}) !important;`">
            <strong :style="`color: var(--${vars.titleColor}) !important;`">
              {{ vars.titleText }}
            </strong>
          </h2>
          <p
            class="mg-bottom-0 center-text"
            v-for="(item, index) in descriptions"
            :key="index"
            :style="`color: var(--${vars.textColor}) !important;`"
          >
            {{ item.description }}
            <br />
          </p>
        </div>
      </div>
      <div class="w-layout-grid grid-3-columns">
        <template v-for="(card, index) in cards" :key="`cards-${index}`">
          <nuxt-link
            id="w-node-_8965249a-dc12-bbb5-f35a-f7e840849c5a-9e5c1e3b"
            :to="card.button_link"
            :external="true"
            class="text-decoration-none w-inline-block"
          >
            <div
              id="w-node-_8965249a-dc12-bbb5-f35a-f7e840849c5b-9e5c1e3b"
              data-w-id="8965249a-dc12-bbb5-f35a-f7e840849c5b"
              style="
                -webkit-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                -moz-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                -ms-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
              "
              class="card icon-top-card-link"
            >
              <div class="mg-bottom-20px">
                <img
                  :src="card.icon"
                  loading="eager"
                  width="60"
                  :alt="card.title"
                  class="shadow-02 border-radius-12px"
                />
              </div>
              <h3 class="card-link-title">
                <strong> {{ card.title }} </strong>
              </h3>
              <p class="color-neutral-600 mg-bottom-24px">
                {{ card.description }}
              </p>
              <div v-if="card.button_label" class="text-200 bold mg-top-auto">
                <div class="link-text color-neutral-800">{{ card.button_label }}</div>
                <div class="line-rounded-icon diagonal-link-arrow link-arrow-right">
                  <span class="color-neutral-800"></span>
                </div>
              </div>
            </div>
          </nuxt-link>
        </template>
      </div>
      <template v-if="vars.buttons1ButtonLabelText">
        <div class="text-center mg-top-40px buttons-row tw-justify-center tw-pt-10">
          <div v-for="(button, index) in buttons" :key="`button-${index}`" class="max-md:tw-w-full">
            <nuxt-link
              :to="button.button_link"
              :external="true"
              class="w-button"
              :class="`${button.button_type} ${index === 0 ? 'button-row' : null}`"
            >
              {{ button.button_label }}
            </nuxt-link>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'Features',

  mixins: [ComponentMixin],

  computed: {
    descriptions() {
      return this.groupedVariables.descriptions;
    },
    cards() {
      return this.groupedVariables.cards;
    },
    buttons() {
      return this.groupedVariables.buttons;
    },
  },
});
</script>
