<template>
  <div class="top-section bg-v2 mb-0 tw-pb-36" :style="`background-color: var(--${vars.backgroundColor}) !important;`">
    <div class="container-default w-container">
      <div id="w-node-_63ad08e3-e26c-0ee8-2d9d-f6515b2f849a-9e5c1e31" class="text-center">
        <div
          data-w-id="63ad08e3-e26c-0ee8-2d9d-f6515b2f849c"
          style="
            -webkit-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            -moz-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            -ms-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
          "
          class="inner-container _668px center"
        >
          <h1 class="display-1 color-neutral-100" :style="`color: var(--${vars.titleColor}) !important;`">
            <strong class="bold-text-25" :style="`color: var(--${vars.titleColor}) !important;`">{{
              vars.titleText
            }}</strong>
          </h1>
        </div>
        <div
          data-w-id="63ad08e3-e26c-0ee8-2d9d-f6515b2f849f"
          style="
            -webkit-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            -moz-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            -ms-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
          "
          class="inner-container _560px center"
        >
          <p
            v-for="(item, index) in descriptions"
            :key="index"
            class="color-neutral-400 center-text tw-mb-1"
            :style="`color: var(--${vars.textColor}) !important;`"
          >
            {{ item.description }}
          </p>
        </div>
        <div
          data-w-id="cc8185ab-ef5a-69bc-6b98-9c13e6fb01cd"
          style="
            -webkit-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            -moz-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            -ms-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
          "
          class="buttons-row tw-justify-center tw-pt-10"
          v-if="vars.buttons1LabelText"
        >
          <div v-for="(button, index) in buttons" :key="`button-${index}`" class="max-md:tw-w-full">
            <nuxt-link
              :to="button.link"
              :external="true"
              class="w-button"
              :class="`${button.type} ${index === 0 ? 'button-row' : null}`"
            >
              {{ button.label }}
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'IntroSection',

  mixins: [ComponentMixin],

  computed: {
    buttons() {
      return this.groupedVariables.buttons;
    },

    descriptions() {
      return this.groupedVariables.descriptions;
    },
  },
});
</script>
