<template>
  <component
    :is="component.component.name"
    v-for="(component, index) in specificComponents"
    :key="`dynamicComponent-${index}`"
    :component="component"
  ></component>
</template>

<script lang="ts">
// layouts
import FooterOne from '~/components/theme3/base/footer/FooterOne.vue';

// pages components
import VideoBackground from '~/components/theme3/components/VideoBackground.vue';
import Banner from '~/components/theme3/components/Banner.vue';
import WhyBox from '~/components/theme3/components/WhyBox.vue';
import Theme3BlogDetail from '~/components/theme3/components/BlogDetail.vue';
import OurValues from '~/components/theme3/components/OurValues.vue';
import Awards from '~/components/theme3/components/Awards.vue';
import CardSection from '~/components/theme3/components/CardSection.vue';
import OurPlan from '~/components/theme3/components/OurPlan.vue';
import OurTeam from '~/components/theme3/components/OurTeam.vue';
import Achievements from '~/components/theme3/components/Achievements.vue';
import Testimonials from '~/components/theme3/components/Testimonials.vue';
import SectionIntro from '~/components/theme3/components/SectionIntro.vue';
import ContentBox from '~/components/theme3/components/ContentBox.vue';
import OurLocations from '~/components/theme3/components/OurLocations.vue';
import SectionInfo from '~/components/theme3/components/SectionInfo.vue';
import Packages from '~/components/theme3/components/Packages.vue';
import BestPackage from '~/components/theme3/components/BestPackage.vue';
import MajorPlatforms from '~/components/theme3/components/MajorPlatforms.vue';
import WhyChooseUs from '~/components/theme3/components/WhyChooseUs.vue';
import CompareTable from '~/components/theme3/components/CompareTable.vue';
import SingleTestimonial from '~/components/theme3/components/SingleTestimonial.vue';
import ComparisonBox from '~/components/theme3/components/ComparisonBox.vue';
import GuideForm from '~/components/theme3/components/GuideForm.vue';
import CircleCTA from '~/components/theme3/components/CircleCTA.vue';
import CustomRedirectToForProperty from '~/components/theme3/components/CustomRedirectToForProperty.vue';
import EstasWidgets from '~/components/theme3/components/EstasWidgets.vue';

// blogs components
import {
  Theme3BlogsPage,
  Theme3BlogsBox,
  LibraryPropertiesOnTheFlySearchBox,
  Theme3PropertiesBox,
  Theme3ComponentsKnowledgeHubBox,
  Theme3PropertiesPropertyDetail,
  Theme3ComponentsFeaturedVideoSection,
  Theme3ComponentsClassicCardGridSection,
  Theme3ComponentsClientTestimonials,
  Theme3ComponentsBranches,
  Theme3ComponentsBranchMapAndContactForm,
} from '#components';

// library components
import FormContainer from '~/components/library/FormContainer.vue';
import Properties from '~/components/library/Properties.vue';
import Register from '~/components/common/register/Register.vue';

// common
import RegisterEntry from '~/components/common/register/RegisterEntry.vue';
import PropertiesEntry from '~/components/common/properties/PropertiesEntry.vue';
import Separator from '~/components/library/Separator.vue';
import FeaturedImage from '~/components/theme3/components/FeaturedImage.vue';
import NotFoundPage from '~/components/theme3/components/NotFoundPage.vue';
import RedirectTo from '~/components/common/RedirectTo.vue';
import KnowledgeHubPage from '~/components/theme3/components/KnowledgeHubPage.vue';
import UpdateProfileEntry from '~/components/library/heads_up_alerts/UpdateProfileEntry.vue';
import LoginBox from '~/components/library/LoginBox.vue';

export default defineNuxtComponent({
  props: {
    specificComponents: {
      required: false,
      default: () => [],
    },
  },

  components: {
    Banner,
    VideoBackground,
    WhyBox,
    BlogDetail: Theme3BlogDetail,
    FormContainer,
    OurValues,
    Awards,
    Properties,
    Register,
    CardSection,
    OurPlan,
    OurTeam,
    ComparisonBox,
    Achievements,
    Testimonials,
    RegisterEntry,
    PropertiesEntry,
    BlogsPage: Theme3BlogsPage,
    BlogsBox: Theme3BlogsBox,
    FooterOne,
    SectionIntro,
    ContentBox,
    OurLocations,
    SectionInfo,
    OnTheFlyPropertySearchBox: LibraryPropertiesOnTheFlySearchBox,
    PropertiesBox: Theme3PropertiesBox,
    KnowledgeHubBox: Theme3ComponentsKnowledgeHubBox,
    Packages,
    BestPackage,
    MajorPlatforms,
    WhyChooseUs,
    CompareTable,
    SingleTestimonial,
    GuideForm,
    CircleCTA,
    PropertyDetail: Theme3PropertiesPropertyDetail,
    Separator,
    FeaturedVideoSection: Theme3ComponentsFeaturedVideoSection,
    ClassicCardGridSection: Theme3ComponentsClassicCardGridSection,
    ClientTestimonials: Theme3ComponentsClientTestimonials,
    FeaturedImage,
    NotFoundPage,
    Branches: Theme3ComponentsBranches,
    BranchMapAndContactForm: Theme3ComponentsBranchMapAndContactForm,
    RedirectTo,
    KnowledgeHubPage,
    CustomRedirectToForProperty,
    EstasWidgets,
    UpdateProfileEntry,
    LoginBox,
  },
});
</script>
