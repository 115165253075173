<template>
  <div class="section bg-neutral-100" :style="`background-color: var(--${vars.backgroundColor}) !important;`">
    <div class="container-default w-container">
      <div
        data-w-id="feb4acb6-6846-dc9e-dbbc-a91145ec0cc8"
        style="transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)"
        class="text-center mg-bottom-72px"
      >
        <div class="inner-container _636px center">
          <h2 class="display-2 mg-bottom-0">
            <strong>{{ vars.titleText }}</strong>
          </h2>
          <p class="mg-bottom-0 center-text">
            <em>{{ vars.descriptionText }}</em>
          </p>
        </div>
      </div>
      <div class="mg-bottom-64px">
        <div
          data-current="Tab 1"
          data-easing="ease"
          data-duration-in="300"
          data-duration-out="100"
          class="grid-2-columns _1fr---1-65fr gap-row-64px w-tabs"
        >
          <div
            id="w-node-feb4acb6-6846-dc9e-dbbc-a91145ec0cce-9e5c1e36"
            data-w-id="feb4acb6-6846-dc9e-dbbc-a91145ec0cce"
            style="transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)"
            class="grid-1-column w-tab-menu"
          >
            <a
              v-for="(tab, index) in tabs"
              :key="index"
              :data-w-tab="`Tab ${index + 1}`"
              class="accordion-tab-link w-inline-block w-tab-link"
              :class="{ last: index === tabs.length - 1 }"
              @click="toggleAccordion(`Tab ${index + 1}`)"
            >
              <div class="accordion-tab-container">
                <div class="accordion-side tab-menu-icon">
                  <img loading="eager" :src="tab.icon" alt="" class="shadow-02 border-radius-10px width-100" />
                </div>
                <div class="accordion-content-wrapper mg-top-8px">
                  <div class="accordion-header">
                    <h3 class="mg-bottom-0">
                      <strong class="bold-text-11">{{ tab.title }}</strong>
                    </h3>
                  </div>
                  <div
                    class="acordion-body"
                    style="
                      height: 0px;
                      transform: translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0)
                        skew(0, 0);
                    "
                  >
                    <div class="accordion-spacer _12px"></div>
                    <p class="color-neutral-600 mg-bottom-0">{{ tab.description }}</p>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div
            id="w-node-feb4acb6-6846-dc9e-dbbc-a91145ec0cf3-9e5c1e36"
            data-w-id="feb4acb6-6846-dc9e-dbbc-a91145ec0cf3"
            style="transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)"
            class="width-100 w-tab-content"
          >
            <div
              v-for="(tab, index) in tabs"
              :key="index"
              :data-w-tab="`Tab ${index + 1}`"
              class="tab-image-right max-w-736px w-tab-pane"
              :class="{ 'w--tab-active': activeTab === `Tab ${index + 1}` }"
            >
              <img loading="eager" :src="tab.background" :alt="`Tab ${index + 1} Image`" class="width-100 tab-image" />
            </div>
          </div>
        </div>
      </div>
      <div class="flex-horizontal tw-pt-10">
        <div v-for="(button, index) in buttons" :key="`button-${index}`" class="max-md:tw-w-full">
          <nuxt-link
            :to="button.link"
            :external="true"
            class="w-button"
            :class="`${button.type} ${index === 0 ? 'button-row' : null}`"
          >
            {{ button.label }}
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'InfoTabs',

  mixins: [ComponentMixin],

  data() {
    return {
      activeTab: 'Tab 1',
    };
  },

  computed: {
    tabs() {
      return this.groupedVariables.tabs;
    },

    buttons() {
      return this.groupedVariables.buttons;
    },
  },

  methods: {
    toggleAccordion(tabId: string) {
      const allAccordionBodies = document.querySelectorAll('.acordion-body') as NodeListOf<HTMLElement>;

      allAccordionBodies.forEach((body) => {
        body.style.height = '0px';
      });

      if (this.activeTab === tabId) {
        this.activeTab = '';
        return;
      }

      // Tıklanan paneli aç
      const clickedAccordionBody = document.querySelector(`[data-w-tab="${tabId}"] .acordion-body`) as HTMLElement;
      if (clickedAccordionBody) {
        const content = clickedAccordionBody.querySelector('p');
        if (content) {
          clickedAccordionBody.style.height = `${content.offsetHeight + 20}px`;
        }
        this.activeTab = tabId;
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      const allAccordionBodies = document.querySelectorAll('.acordion-body') as NodeListOf<HTMLElement>;
      allAccordionBodies.forEach((body) => {
        body.style.height = '0px';
      });

      // İlk tab'ı otomatik olarak aç
      const defaultAccordionBody = document.querySelector('[data-w-tab="Tab 1"] .acordion-body') as HTMLElement;
      if (defaultAccordionBody) {
        const content = defaultAccordionBody.querySelector('p');
        if (content) {
          defaultAccordionBody.style.height = `${content.offsetHeight + 20}px`;
        }
      }
    });
  },
});
</script>

<style scoped>
.acordion-body {
  transition: height 0.3s ease-in-out;
  overflow: hidden;
}

.accordion-tab-link {
  cursor: pointer;
}

/* Yeni image stilleri */
.image-container {
  width: 100%;
  height: 500px; /* Sabit bir yükseklik belirliyoruz */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.tab-image {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Resmi orantılı şekilde sığdırır */
  max-height: 500px;
}

/* Mobil responsive için */
@media screen and (max-width: 920px) {
  .tab-image {
    display: none;
  }
}
</style>
