<template>
  <div
    id="press"
    class="section bg-neutral-800 position-relative---z-index-1 section-low-bottom"
    :style="`background-color: var(--${vars.backgroundColor}) !important;`"
  >
    <div class="container-default w-container">
      <div class="text-center mg-bottom-32px">
        <div class="inner-container _706px center">
          <h2 class="display-2 color-neutral-100 mg-bottom-0" :style="`color: var(--${vars.titleColor}) !important;`">
            {{ vars.titleText }}
          </h2>
        </div>
      </div>
      <div fs-cmsslider-element="list" class="w-dyn-list" style="display: none">
        <div role="list" class="w-dyn-items"></div>
      </div>
      <div
        data-delay="4000"
        data-animation="slide"
        class="slider-wrapper slider-buttons-bottom-center w-slider"
        data-autoplay="false"
        data-easing="ease"
        data-hide-arrows="false"
        data-disable-swipe="false"
        data-autoplay-limit="0"
        data-nav-spacing="3"
        data-duration="500"
        data-infinite="true"
        fs-cmsslider-element="slider"
        role="region"
        aria-label="carousel"
      >
        <div class="slider-mask width-1012px w-slider-mask" id="w-slider-mask-0">
          <div aria-live="off" aria-atomic="true" class="w-slider-aria-label" data-wf-ignore=""></div>
          <div
            v-for="(card, index) in cards"
            :key="`card-${index}`"
            class="slide-item-mg mg-right-32px w-slide"
            aria-label="`{{ index + 1 }} of {{ cards.length }}`"
            role="group"
            style="transition: all; transform: translateX(0px); opacity: 1"
          >
            <div class="w-dyn-item">
              <a
                :href="card.link"
                target="_blank"
                class="text-decoration-none w-inline-block"
                :style="`color: var(--${vars.textColor}) !important;`"
                ><div
                  class="card testimonial-slider-card pressslider"
                  :style="`background-color: var(--${vars.cardBackgroundColor}) !important;`"
                >
                  <div
                    id="w-node-beb5ff07-e166-bb61-b504-085ebd0176ed-9e5c1e36"
                    class="grid-1-column mg-top-0-mbl align-top"
                  >
                    <div
                      id="w-node-beb5ff07-e166-bb61-b504-085ebd0176ef-9e5c1e36"
                      class="flex align-center gap-column-16px text-decoration-none mg-bottom-16px"
                    >
                      <div class="div-block-5 text-decoration-none">
                        <div class="color-neutral-100">
                          {{ card.title }}
                        </div>
                      </div>
                    </div>
                    <library-image
                      :src="card.background"
                      :w-ratio="1"
                      :h-ratio="1"
                      style="border-radius: 10px !important"
                    >
                    </library-image>
                  </div>
                  <div
                    id="w-node-beb5ff07-e166-bb61-b504-085ebd0176f2-9e5c1e36"
                    class="testimonial-card---text-container"
                  >
                    <img
                      src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/644a6cebe40c042d1c3fdeaf_quotation-mark-sleek-webflow-ecommerce-template.svg"
                      loading="eager"
                      alt=""
                      class="max-w-24px-mbp mg-bottom-40px"
                    />
                    <p class="paragraph-large color-neutral-400 mg-bottom-0 overflow-hidden text-ellipsis-6 fs-18">
                      {{ card.description }}
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div
          class="slider-btn-light left-btn-center w-slider-arrow-left"
          role="button"
          tabindex="0"
          aria-controls="w-slider-mask-0"
          aria-label="previous slide"
        >
          <div></div>
        </div>
        <div
          class="slider-btn-white right-btn-center w-slider-arrow-right"
          role="button"
          tabindex="0"
          aria-controls="w-slider-mask-0"
          aria-label="next slide"
        >
          <div></div>
        </div>
        <div class="hidden-on-desktop w-slider-nav w-round">
          <div
            class="w-slider-dot w-active"
            data-wf-ignore=""
            aria-label="Show slide 1 of 5"
            aria-pressed="true"
            role="button"
            tabindex="0"
            style="margin-left: 3px; margin-right: 3px"
          ></div>
          <div
            class="w-slider-dot"
            data-wf-ignore=""
            aria-label="Show slide 2 of 5"
            aria-pressed="false"
            role="button"
            tabindex="-1"
            style="margin-left: 3px; margin-right: 3px"
          ></div>
          <div
            class="w-slider-dot"
            data-wf-ignore=""
            aria-label="Show slide 3 of 5"
            aria-pressed="false"
            role="button"
            tabindex="-1"
            style="margin-left: 3px; margin-right: 3px"
          ></div>
          <div
            class="w-slider-dot"
            data-wf-ignore=""
            aria-label="Show slide 4 of 5"
            aria-pressed="false"
            role="button"
            tabindex="-1"
            style="margin-left: 3px; margin-right: 3px"
          ></div>
          <div
            class="w-slider-dot"
            data-wf-ignore=""
            aria-label="Show slide 5 of 5"
            aria-pressed="false"
            role="button"
            tabindex="-1"
            style="margin-left: 3px; margin-right: 3px"
          ></div>
        </div>
      </div>
      <div class="text-center mg-top-40px">
        <div v-for="(button, index) in buttons" :key="`button-${index}`" class="max-md:tw-w-full">
          <nuxt-link
            :to="button.link"
            :external="true"
            class="w-button"
            :class="`${button.type} ${index === 0 ? 'button-row' : null}`"
          >
            {{ button.label }}
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'Press',

  mixins: [ComponentMixin],

  computed: {
    cards() {
      return this.groupedVariables.cards;
    },
    buttons() {
      return this.groupedVariables.buttons;
    },
  },
});
</script>
