<template>
  <div class="section" :style="`background-color: var(--${vars.backgroundColor}) !important;`">
    <div class="container-default w-container">
      <div class="grid-2-columns _2-col-mbl mg-bottom-56px">
        <h2
          id="w-node-_17159282-ae85-72ed-bf75-3b888f1f6e90-9e5c1e36"
          data-w-id="17159282-ae85-72ed-bf75-3b888f1f6e90"
          style="
            transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
              skew(0deg, 0deg);
            transform-style: preserve-3d;
            opacity: 1;
          "
          class="display-2 mg-bottom-0 text-left"
          :style="`color: var(--${vars.titleColor}) !important;`"
        >
          {{ vars.titleText }}
        </h2>
        <div
          id="w-node-_9b92bd97-4ce2-68d3-c9fd-56531d931d48-9e5c1e36"
          data-w-id="9b92bd97-4ce2-68d3-c9fd-56531d931d48"
          style="
            transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
              skew(0deg, 0deg);
            transform-style: preserve-3d;
            opacity: 1;
          "
        >
          <nuxt-link :external="true" :to="vars.buttonLinkUrl" class="btn-primary w-button">{{
            vars.buttonLabelText
          }}</nuxt-link>
        </div>
      </div>
      <div class="grid-3-columns">
        <div
          v-for="(card, index) in cards"
          :key="`card-${index}`"
          id="w-node-_9cf4ae18-0441-b9cb-544e-fc8edfb06a56-9e5c1e36"
          data-w-id="9cf4ae18-0441-b9cb-544e-fc8edfb06a56"
          style="
            transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
              skew(0deg, 0deg);
            transform-style: preserve-3d;
            opacity: 1;
          "
          class="card pd-40px---40px---48px"
        >
          <div class="mg-bottom-40px mg-bottom-20px-mbl">
            <img :src="card.icon" loading="eager" alt="" class="shadow-02 border-radius-12px" />
          </div>
          <h3 :style="`color: var(--${vars.titleColor}) !important;`">
            <strong :style="`color: var(--${vars.titleColor}) !important;`">{{ card.title }}</strong>
          </h3>
          <p class="mg-bottom-0" :style="`color: var(--${vars.textColor}) !important;`">{{ card.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'OurBrand',

  mixins: [ComponentMixin],

  computed: {
    cards() {
      return this.groupedVariables.cards;
    },
  },
});
</script>
