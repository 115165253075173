<template>
  <lazy-theme0-base-not-found :is-full-screen-error="true">
    <template #title>
      <div class="top-section bg-v17">
        <div class="container-default w-container">
          <div class="text-center">
            <div class="inner-container _608px center">
              <div
                data-w-id="de50bbf4-3d74-7d43-31ba-3709d9313cef"
                style="
                  transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
                    skew(0deg, 0deg);
                  opacity: 1;
                  transform-style: preserve-3d;
                "
                class="_404-not-found"
              >
                404
              </div>
              <h1
                data-w-id="108db2ce-32e0-49c5-d6a8-7d68c5eeb553"
                style="
                  transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
                    skew(0deg, 0deg);
                  opacity: 1;
                  transform-style: preserve-3d;
                "
                class="display-1 color-neutral-100 mg-bottom-12px"
                v-if="vars.titleText"
              >
                {{ vars.titleText }}
              </h1>
              <p
                v-if="vars.descriptionText"
                data-w-id="c48f7933-5cde-ba9e-f331-ced393214929"
                style="
                  transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
                    skew(0deg, 0deg);
                  opacity: 1;
                  transform-style: preserve-3d;
                "
                class="color-neutral-400 mg-bottom-32px !tw-text-center"
              >
                {{ vars.descriptionText }}
              </p>
              <div
                data-w-id="55ecaaf7-5b20-24ca-1f27-1c568cd8a5e4"
                style="
                  transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
                    skew(0deg, 0deg);
                  opacity: 1;
                  transform-style: preserve-3d;
                "
              >
                <nuxt-link
                  v-if="vars.buttonLinkUrl"
                  :external="true"
                  :to="vars.buttonLinkUrl"
                  class="btn-primary white w-button"
                  >{{ vars.buttonLabelText }}</nuxt-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </lazy-theme0-base-not-found>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'NotFoundPage',

  mixins: [ComponentMixin],

  setup() {
    const event = useRequestEvent();

    // event will be undefined in the browser
    if (event) {
      // Set the status message as well
      setResponseStatus(event, 404, 'Page Not Found');
    }
  },

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  computed: {
    descriptions() {
      return this.groupedVariables.descriptions;
    },
    buttons() {
      return this.groupedVariables.buttons;
    },
  },
});
</script>
<style scoped>
::v-deep(h1) {
  font-size: 60px !important;
  padding-top: 16px;
}
</style>
