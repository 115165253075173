<template>
  <component
    :is="component.component.name"
    v-for="(component, index) in specificComponents"
    :key="`dynamicComponent-${index}`"
    :component="component"
  ></component>
</template>

<script lang="ts">
// layouts
import FooterOne from '~/components/theme5/base/footer/FooterOne.vue';
import HeaderOne from '~/components/theme5/base/header/HeaderOne.vue';

// pages components
import HeroSection from '~/components/theme5/components/HeroSection.vue';
import InfoBox from '~/components/theme5/components/InfoBox.vue';
import WhySection from '~/components/theme5/components/WhySection.vue';
import Awards from '~/components/theme5/components/Awards.vue';
import OurTeam from '~/components/theme5/components/OurTeam.vue';
import CountSection from '~/components/theme5/components/CountSection.vue';
import OurGuide from '~/components/theme5/components/OurGuide.vue';
import BlogSection from '~/components/theme5/blogs/BlogSection.vue';
import SocialGallery from '~/components/theme5/components/SocialGallery.vue';
import OurValuations from '~/components/theme5/components/OurValuations.vue';
import SingleTestimonials from '~/components/theme5/components/SingleTestimonials.vue';
import Testimonials from '~/components/theme5/components/Testimonials.vue';
import TakeAction from '~/components/theme5/components/TakeAction.vue';
import TextBanner from '~/components/theme5/components/TextBanner.vue';
import OurValues from '~/components/theme5/components/OurValues.vue';
import MarketingReviews from '~/components/theme5/components/MarketingReviews.vue';
import ContactUs from '~/components/theme5/components/ContactUs.vue';
import ContentBox from '~/components/theme5/components/ContentBox.vue';
import OurLocations from '~/components/theme5/components/OurLocations.vue';
import IntroSection from '~/components/theme5/components/IntroSection.vue';
import AccordionList from '~/components/theme5/components/AccordionList.vue';
import WhyBox from '~/components/theme5/components/WhyBox.vue';
import GuideSection from '~/components/theme5/components/GuideSection.vue';

// library components
import FormContainer from '~/components/library/FormContainer.vue';
import Properties from '~/components/library/Properties.vue';
import Register from '~/components/common/register/Register.vue';
import LoginBox from '~/components/library/LoginBox.vue';

import {
  Theme5PropertiesPropertyDetail,
  LibraryPropertiesOnTheFlySearchBox,
  Theme5ComponentsKnowledgeHubBox,
  Theme5ComponentsBranchMapAndContactForm,
  Theme5BlogsPage,
  Theme5BlogsBlogDetail,
} from '#components';

// common
import RegisterEntry from '~/components/common/register/RegisterEntry.vue';
import PropertiesEntry from '~/components/common/properties/PropertiesEntry.vue';
import Separator from '~/components/library/Separator.vue';
import RedirectTo from '~/components/common/RedirectTo.vue';
import NotFoundPage from '~/components/theme5/components/NotFoundPage.vue';
import KnowledgeHubPage from '~/components/theme5/components/KnowledgeHubPage.vue';
import UpdateProfileEntry from '~/components/library/heads_up_alerts/UpdateProfileEntry.vue';

import { Theme5PropertiesLatestProperties } from '#components';

export default defineNuxtComponent({
  props: {
    specificComponents: {
      required: false,
      default: () => [],
    },
  },

  components: {
    FormContainer,
    Properties,
    Register,
    RegisterEntry,
    PropertiesEntry,
    FooterOne,
    Separator,
    RedirectTo,
    NotFoundPage,
    HeroSection,
    PropertyDetail: Theme5PropertiesPropertyDetail,
    HeaderOne,
    InfoBox,
    WhySection,
    LatestProperties: Theme5PropertiesLatestProperties,
    Awards,
    OurTeam,
    OnTheFlyPropertySearchBox: LibraryPropertiesOnTheFlySearchBox,
    CountSection,
    OurGuide,
    BlogSection,
    SocialGallery,
    OurValuations,
    SingleTestimonials,
    Testimonials,
    TakeAction,
    TextBanner,
    OurValues,
    MarketingReviews,
    ContactUs,
    ContentBox,
    OurLocations,
    BranchMapAndContactForm: Theme5ComponentsBranchMapAndContactForm,
    KnowledgeHubBox: Theme5ComponentsKnowledgeHubBox,
    KnowledgeHubPage,
    IntroSection,
    BlogsPage: Theme5BlogsPage,
    BlogDetail: Theme5BlogsBlogDetail,
    AccordionList,
    WhyBox,
    GuideSection,
    UpdateProfileEntry,
    LoginBox,
  },
});
</script>
