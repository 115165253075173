<template>
  <div class="section bg-neutral-800">
    <div class="container-default w-container">
      <div class="grid-2-columns _1fr---1-32fr gap-row-64px">
        <div
          id="w-node-_34f2d7ad-26fe-43f0-51ba-b62e02bbb0ea-4c5584f4"
          data-w-id="34f2d7ad-26fe-43f0-51ba-b62e02bbb0ea"
          style="
            transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
              skew(0deg, 0deg);
            opacity: 1;
            transform-style: preserve-3d;
          "
          class="sticky-top static-mbl"
        >
          <h2 class="display-2 color-neutral-100 mg-bottom-8px">{{ vars.titleText }}</h2>
          <p class="color-neutral-400">
            Lorem ipsum dolor consectetur adipiscing elitut dolor aliquam, purus sit amet luctus venenatis, lectus magna
            fringilla urna porttitor rhoncus dolor.
          </p>
          <a href="#open-positions" class="btn-primary white w-button">Join our team</a>
        </div>
        <div data-w-id="1b54bb65-6a5f-b177-bfd7-8317a4b4ab59" style="opacity: 1" class="card faqs-card dark">
          <div
            data-w-id="53ab85d8-41cb-ebef-4d2d-bd64e983bdde"
            class="accordion-item-wrapper v3 border-neutral-600 first"
          >
            <div class="accordion-content-wrapper width-100">
              <div class="accordion-header header-and-icon">
                <div class="flex align-center gap-column-16px">
                  <img
                    src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/634dc77d85d300bb41cc7c01_remote-perk-icon-sleek-webflow-ecommerce-template.svg"
                    loading="eager"
                    alt="Remote - Sleek X Webflow Template"
                    class="max-w-36px-mbl"
                  />
                  <h3 class="color-neutral-100 mg-bottom-0">Remote</h3>
                </div>
                <div class="accordion-icon-single">
                  <div
                    class="line-rounded-icon accordion-chevron-arrow-dark"
                    style="
                      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
                        skew(0deg, 0deg);
                      transform-style: preserve-3d;
                      color: rgb(78, 78, 79);
                    "
                  >
                    
                  </div>
                </div>
              </div>
              <div
                class="acordion-body max-w-492px"
                style="
                  transform: translate3d(0px, 20px, 0px) scale3d(0.96, 0.96, 1) rotateX(0deg) rotateY(0deg)
                    rotateZ(0deg) skew(0deg, 0deg);
                  transform-style: preserve-3d;
                  opacity: 0;
                  width: 492px;
                  height: 0px;
                "
              >
                <div class="accordion-spacer _12px"></div>
                <p class="color-neutral-400 mg-bottom-0">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore.
                </p>
              </div>
            </div>
          </div>
          <div data-w-id="95e82349-8818-b24d-e911-b6026cfad625" class="accordion-item-wrapper v3 border-neutral-600">
            <div class="accordion-content-wrapper width-100">
              <div class="accordion-header header-and-icon">
                <div class="flex align-center gap-column-16px">
                  <img
                    src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/634dc77ca3be17cf63b199d1_flexible-hours-perk-icon-sleek-webflow-ecommerce-template.svg"
                    loading="eager"
                    alt="Flexible Hours - Sleek X Webflow Template"
                    class="max-w-36px-mbl"
                  />
                  <h3 class="color-neutral-100 mg-bottom-0">Flexible hours</h3>
                </div>
                <div class="accordion-icon-single">
                  <div
                    class="line-rounded-icon accordion-chevron-arrow-dark"
                    style="
                      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(90deg)
                        skew(0deg, 0deg);
                      transform-style: preserve-3d;
                      color: rgb(255, 255, 255);
                    "
                  >
                    
                  </div>
                </div>
              </div>
              <div
                class="acordion-body max-w-492px"
                style="
                  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
                    skew(0deg, 0deg);
                  transform-style: preserve-3d;
                  opacity: 1;
                  width: 492px;
                "
              >
                <div class="accordion-spacer _12px"></div>
                <p class="color-neutral-400 mg-bottom-0">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore.
                </p>
              </div>
            </div>
          </div>
          <div data-w-id="2dd67302-08d6-a220-f385-ec0c5b5eeb56" class="accordion-item-wrapper v3 border-neutral-600">
            <div class="accordion-content-wrapper width-100">
              <div class="accordion-header header-and-icon">
                <div class="flex align-center gap-column-16px">
                  <img
                    src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/634dc3fb720fb286407a3046_unlimited-pto-perk-icon-sleek-webflow-ecommerce-template.svg"
                    loading="eager"
                    alt="Unlimited PTO - Sleek X Webflow Template"
                    class="max-w-36px-mbl"
                  />
                  <h3 class="color-neutral-100 mg-bottom-0">Unlimited PTO</h3>
                </div>
                <div class="accordion-icon-single">
                  <div class="line-rounded-icon accordion-chevron-arrow-dark"></div>
                </div>
              </div>
              <div
                class="acordion-body max-w-492px"
                style="
                  height: 0px;
                  transform: translate3d(0px, 20px, 0px) scale3d(0.96, 0.96, 1) rotateX(0deg) rotateY(0deg)
                    rotateZ(0deg) skew(0deg, 0deg);
                  transform-style: preserve-3d;
                  opacity: 0;
                "
              >
                <div class="accordion-spacer _12px"></div>
                <p class="color-neutral-400 mg-bottom-0">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore.
                </p>
              </div>
            </div>
          </div>
          <div data-w-id="1e74aadc-97d6-b962-d887-aef6a0415c1c" class="accordion-item-wrapper v3 border-neutral-600">
            <div class="accordion-content-wrapper width-100">
              <div class="accordion-header header-and-icon">
                <div class="flex align-center gap-column-16px">
                  <img
                    src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/634dc77dbea80cb7bdd544ee_great-culture-perk-icon-sleek-webflow-ecommerce-template.svg"
                    loading="eager"
                    alt="Great Culture - Sleek X Webflow Template"
                    class="max-w-36px-mbl"
                  />
                  <h3 class="color-neutral-100 mg-bottom-0">Great culture</h3>
                </div>
                <div class="accordion-icon-single">
                  <div class="line-rounded-icon accordion-chevron-arrow-dark"></div>
                </div>
              </div>
              <div
                class="acordion-body max-w-492px"
                style="
                  height: 0px;
                  transform: translate3d(0px, 20px, 0px) scale3d(0.96, 0.96, 1) rotateX(0deg) rotateY(0deg)
                    rotateZ(0deg) skew(0deg, 0deg);
                  transform-style: preserve-3d;
                  opacity: 0;
                "
              >
                <div class="accordion-spacer _12px"></div>
                <p class="color-neutral-400 mg-bottom-0">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore.
                </p>
              </div>
            </div>
          </div>
          <div data-w-id="7de7e04c-15ca-6e02-b589-ac8ac88cf989" class="accordion-item-wrapper v3 border-neutral-600">
            <div class="accordion-content-wrapper width-100">
              <div class="accordion-header header-and-icon">
                <div class="flex align-center gap-column-16px">
                  <img
                    src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/634dc77dd431b727077cfeda_career-growth-perk-icon-sleek-webflow-ecommerce-template.svg"
                    loading="eager"
                    alt="Career Growth - Sleek X Webflow Template"
                    class="max-w-36px-mbl"
                  />
                  <h3 class="color-neutral-100 mg-bottom-0">Career growth</h3>
                </div>
                <div class="accordion-icon-single">
                  <div class="line-rounded-icon accordion-chevron-arrow-dark"></div>
                </div>
              </div>
              <div
                class="acordion-body max-w-492px"
                style="
                  height: 0px;
                  transform: translate3d(0px, 20px, 0px) scale3d(0.96, 0.96, 1) rotateX(0deg) rotateY(0deg)
                    rotateZ(0deg) skew(0deg, 0deg);
                  transform-style: preserve-3d;
                  opacity: 0;
                "
              >
                <div class="accordion-spacer _12px"></div>
                <p class="color-neutral-400 mg-bottom-0">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore.
                </p>
              </div>
            </div>
          </div>
          <div
            data-w-id="b434b37b-3bc6-4dc0-3133-e94520556123"
            class="accordion-item-wrapper v3 border-neutral-600 last"
          >
            <div class="accordion-content-wrapper width-100">
              <div class="accordion-header header-and-icon">
                <div class="flex align-center gap-column-16px">
                  <img
                    src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/634dc77db215c423cc038674_medical-insurance-perk-icon-sleek-webflow-ecommerce-template.svg"
                    loading="eager"
                    alt="Medical Insurance - Sleek X Webflow Template"
                    class="max-w-36px-mbl"
                  />
                  <h3 class="color-neutral-100 mg-bottom-0">Medical insurance</h3>
                </div>
                <div class="accordion-icon-single">
                  <div class="line-rounded-icon accordion-chevron-arrow-dark"></div>
                </div>
              </div>
              <div
                class="acordion-body max-w-492px"
                style="
                  height: 0px;
                  transform: translate3d(0px, 20px, 0px) scale3d(0.96, 0.96, 1) rotateX(0deg) rotateY(0deg)
                    rotateZ(0deg) skew(0deg, 0deg);
                  transform-style: preserve-3d;
                  opacity: 0;
                "
              >
                <div class="accordion-spacer _12px"></div>
                <p class="color-neutral-400 mg-bottom-0">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'Banner',

  mixins: [ComponentMixin],
});
</script>
