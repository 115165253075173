<template>
  <div
    class="bg-v4 section section-low-bottom section-low-top"
    :style="`background-color: var(--${vars.backgroundColor}) !important; color: var(--${vars.textColor});`"
  >
    <div class="container-default w-container">
      <div class="grid-2-columns _1fr---1-65fr gap-row-64px">
        <div id="w-node-_9908139f-2023-dfa2-a5cc-fee141656b80-9e5c1e5a">
          <h1
            :style="`color: var(--${vars.textColor}) !important;`"
            data-w-id="c1f899c4-7ecb-aff7-2e7f-f2fc734d9d14"
            style="
              transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
                skew(0deg, 0deg);
              opacity: 1;
              transform-style: preserve-3d;
            "
            class="display-1 color-neutral-100 mg-bottom-12px"
          >
            {{ vars.titleText }}
          </h1>
          <p
            v-for="(item, index) in descriptions"
            :style="`color: var(--${vars.textColor}) !important;`"
            data-w-id="6f66d181-5a80-7b1a-9ff9-9de709f6d32d"
            style="
              transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
                skew(0deg, 0deg);
              opacity: 1;
              transform-style: preserve-3d;
            "
            class="color-neutral-400 mg-bottom-40px"
          >
            {{ item.description }}
          </p>
          <div
            data-w-id="937cf8bb-c27e-9c84-1b59-d75a8842f9ae"
            style="
              transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
                skew(0deg, 0deg);
              opacity: 1;
              transform-style: preserve-3d;
            "
            class="grid-1-column gap-row-20px tw-mt-14"
          >
            <a
              v-for="(link, index) in links"
              id="w-node-ebd3970d-d16b-d36b-675e-a8666411c7dd-9e5c1e5a"
              data-w-id="ebd3970d-d16b-d36b-675e-a8666411c7dd"
              :key="index"
              :href="link.contact_link"
              class="contact-link-dark w-inline-block"
            >
              <img :src="link.contact_icon" alt="contact icon" />
              <div>
                <div class="heading-h3-size color-neutral-400 mg-bottom-8px tw-text-[#d9d9d9]">
                  {{ link.contact_description }}
                </div>
                <div class="flex align-center">
                  <div
                    class="text-300 medium color-neutral-100 itemsc tw-flex tw-flex-nowrap tw-items-center tw-whitespace-nowrap"
                  >
                    {{ link.contact_title }}
                    <div
                      class="line-rounded-icon diagonal-link-arrow contact-link-dark---icon"
                      style="
                        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
                          skew(0deg, 0deg);
                        transform-style: preserve-3d;
                      "
                    >
                      
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div
          id="w-node-_1294d57e-5332-5dcd-afa5-3f78438e3ba6-9e5c1e5a"
          data-w-id="1294d57e-5332-5dcd-afa5-3f78438e3ba6"
          style="
            transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
              skew(0deg, 0deg);
            opacity: 1;
            transform-style: preserve-3d;
          "
          class="inner-container _626px width-100"
        >
          <div class="card form w-form" style="background-color: var(--section_primary)">
            <library-form
              v-if="form"
              :form="form"
              version="theme0"
              :isFormNameVisible="false"
              :component="component"
            ></library-form>
            <div class="success-message w-form-done" tabindex="-1" role="region" aria-label="Contact V1 Form success">
              <div>
                <div class="line-rounded-icon success-message-check large">
                  <span class="color-neutral-100"></span>
                </div>
                <h3 class="color-neutral-300">Thank you</h3>
                <div class="color-neutral-400">Thanks for reaching out. We will get back to you soon.</div>
              </div>
            </div>
            <div class="error-message w-form-fail" tabindex="-1" role="region" aria-label="Contact V1 Form failure">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'ContactForm',

  mixins: [ComponentMixin],

  data() {
    return {
      form: null,
    };
  },

  created() {
    this.form = this.parseFormV2(this.vars.contactForm);
  },

  computed: {
    descriptions() {
      return this.groupedVariables.descriptions;
    },

    links() {
      return this.groupedVariables.links;
    },
  },
});
</script>
