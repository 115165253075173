<template>
  <section
    v-if="variants.v1 === variant"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor});color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
  >
    <LibraryPopupsVideoPopup
      v-if="vars.isPopupBool && vars.videoUrl"
      :videoUrl="vars.videoUrl"
      v-model:is-visible="isVisible"
      :portrait="vars.isVerticalBool"
    />
    <div
      class="tw-flex tw-w-full tw-max-w-[610px] tw-flex-col-reverse tw-items-center tw-gap-y-8 lg:tw-max-w-none lg:tw-gap-x-32 xl:tw-gap-x-40"
      :class="{ 'lg:tw-flex-row-reverse': vars.isReversedBool, 'lg:tw-flex-row': !vars.isReversedBool }"
    >
      <div class="tw-flex tw-flex-col tw-items-start tw-gap-4 lg:tw-basis-[70%] xl:tw-basis-[90%]">
        <small v-if="vars.altTitleText">{{ vars.altTitleText }}</small>
        <h2>{{ vars.titleText }}</h2>
        <div class="tw-mt-5 tw-flex tw-flex-col tw-gap-y-4">
          <p v-for="(item, index) in descriptions" :key="`infobox-descriptions-${index}`">
            {{ item.description }}
          </p>
        </div>
        <nuxt-link
          :external="true"
          :class="`n-${vars.buttonTypeText} n-btn tw-mt-2`"
          :to="vars.buttonLinkUrl"
          v-if="vars.buttonLinkUrl"
        >
          <div class="n-btn-container">
            <div class="n-btn-group">
              <div class="n-btn-t1">{{ vars.buttonLabelText }}</div>
              <div class="n-btn-t2">{{ vars.buttonLabelText }}</div>
            </div>
          </div>
        </nuxt-link>
      </div>
      <div class="tw-w-full">
        <iframe
          v-if="!vars.isPopupBool && vars.videoUrl"
          class="tw-w-full tw-rounded-xl tw-object-cover"
          :src="vars.videoUrl"
          style="border-radius: var(--rounded)"
          frameborder="0"
          allowfullscreen
          :style="vars.isVerticalBool ? 'aspect-ratio:5/8;' : 'aspect-ratio: 16/9;'"
        ></iframe>

        <LibraryImage
          v-if="vars.photoImage && !vars.videoUrl"
          :src="vars.photoImage"
          :w-ratio="vars.isVerticalBool ? 9 : 645"
          :h-ratio="vars.isVerticalBool ? 16 : 430"
          style="border-radius: var(--rounded)"
        />

        <div
          v-if="vars.photoImage && vars.videoUrl && vars.isPopupBool"
          class="tw-relative tw-w-full"
          @click="isVisible = true"
        >
          <LibraryImage
            :src="vars.photoImage"
            :alt="vars.titleText"
            class="tw-w-full tw-cursor-pointer"
            :w-ratio="vars.isVerticalBool ? 9 : 645"
            :h-ratio="vars.isVerticalBool ? 16 : 430"
            style="border-radius: var(--rounded)"
          />
          <button class="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2">
            <svg width="80" height="80" viewBox="0 0 162 162" xmlns="http://www.w3.org/2000/svg">
              <circle cx="81" cy="81" r="81" fill="white" />
              <polygon points="65,45 115,81 65,117" fill="black" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </section>

  <section
    v-if="variants.v2 === variant"
    class="n-section-tertiary"
    :style="`background-color: var(--c-${vars.backgroundColor});color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'};${!vars.isReversedBool ? 'padding-left: calc((100vw - 100%) / 2) !important;' : 'padding-right: calc((100vw - 100%) / 2) !important;'}`"
  >
    <LibraryPopupsVideoPopup
      v-if="vars.isPopupBool && vars.videoUrl"
      :videoUrl="vars.videoUrl"
      v-model:is-visible="isVisible"
      :portrait="vars.isVerticalBool"
    />
    <div
      class="tw-flex tw-w-full tw-max-w-[610px] tw-flex-col-reverse tw-items-center tw-gap-y-8 lg:tw-max-w-none lg:tw-gap-x-24 xl:tw-gap-x-32"
      :class="{ 'lg:tw-flex-row-reverse': vars.isReversedBool, 'lg:tw-flex-row': !vars.isReversedBool }"
    >
      <div class="tw-flex tw-flex-col tw-items-start tw-gap-4 tw-self-end lg:tw-basis-[70%] xl:tw-basis-[90%]">
        <NuxtImg v-if="vars.logoImage" :src="vars.logoImage" class="tw-size-12 lg:tw-size-16" />
        <h2>{{ vars.titleText }}</h2>
        <div class="tw-mt-5 tw-flex tw-flex-col tw-gap-y-4">
          <p v-for="(item, index) in descriptions" :key="`infobox-descriptions-${index}`">
            {{ item.description }}
          </p>
        </div>
        <nuxt-link
          :external="true"
          :class="`n-${vars.buttonTypeText} n-btn tw-mt-2`"
          :to="vars.buttonLinkUrl"
          v-if="vars.buttonLinkUrl"
          :target="isExternalLink(vars.buttonLinkUrl) ? '_blank' : '_self'"
        >
          <div class="n-btn-container">
            <div class="n-btn-group">
              <div class="n-btn-t1">{{ vars.buttonLabelText }}</div>
              <div class="n-btn-t2">{{ vars.buttonLabelText }}</div>
            </div>
          </div>
        </nuxt-link>
      </div>
      <div class="tw-w-full" :class="{ 'tw-max-w-[400px]': vars.isVerticalBool }">
        <iframe
          v-if="!vars.isPopupBool && vars.videoUrl"
          class="tw-w-full tw-rounded-[var(--rounded)] tw-object-cover"
          :src="vars.videoUrl"
          :class="{
            'lg:tw-rounded-none lg:tw-rounded-br-[var(--rounded)] lg:tw-rounded-tr-[var(--rounded)]':
              vars.isReversedBool,
            'lg:tw-rounded-none lg:tw-rounded-bl-[var(--rounded)] lg:tw-rounded-tl-[var(--rounded)]':
              !vars.isReversedBool,
          }"
          style="border-radius: var(--rounded)"
          :style="vars.isVerticalBool ? ' aspect-ratio:5/8;' : ' aspect-ratio: 16/9; '"
          frameborder="0"
          allowfullscreen
        ></iframe>

        <!-- Fotoğraf gösterimi -->
        <div
          class="tw-relative tw-w-full tw-overflow-hidden tw-rounded-[var(--rounded)]"
          :class="{
            'lg:tw-rounded-none lg:tw-rounded-br-[var(--rounded)] lg:tw-rounded-tr-[var(--rounded)]':
              vars.isReversedBool,
            'lg:tw-rounded-none lg:tw-rounded-bl-[var(--rounded)] lg:tw-rounded-tl-[var(--rounded)]':
              !vars.isReversedBool,
          }"
        >
          <LibraryImage
            v-if="vars.photoImage && !vars.videoUrl"
            :src="vars.photoImage"
            :w-ratio="vars.isVerticalBool ? 9 : 645"
            :h-ratio="vars.isVerticalBool ? 16 : 430"
            style="border-radius: var(--rounded)"
          />
        </div>

        <div
          v-if="vars.photoImage && vars.videoUrl && vars.isPopupBool"
          class="tw-relative tw-w-full tw-overflow-hidden tw-rounded-[var(--rounded)]"
          @click="isVisible = true"
          :class="{
            'lg:tw-rounded-none lg:tw-rounded-br-[var(--rounded)] lg:tw-rounded-tr-[var(--rounded)]':
              vars.isReversedBool,
            'lg:tw-rounded-none lg:tw-rounded-bl-[var(--rounded)] lg:tw-rounded-tl-[var(--rounded)]':
              !vars.isReversedBool,
          }"
        >
          <LibraryImage
            :src="vars.photoImage"
            :alt="vars.titleText"
            class="tw-w-full tw-cursor-pointer"
            :w-ratio="vars.isVerticalBool ? 9 : 645"
            :h-ratio="vars.isVerticalBool ? 16 : 430"
            style="border-radius: var(--rounded)"
          />
          <button class="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2">
            <svg width="80" height="80" viewBox="0 0 162 162" xmlns="http://www.w3.org/2000/svg">
              <circle cx="81" cy="81" r="81" fill="white" />
              <polygon points="65,45 115,81 65,117" fill="black" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
export default defineNuxtComponent({
  mixins: [ComponentMixin],

  data() {
    return {
      isVisible: false,
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  computed: {
    descriptions() {
      return this.groupedVariables.descriptions;
    },
  },

  methods: {
    isExternalLink(url: string) {
      return url.includes('http');
    },
  },
});
</script>
