<template>
  <div
    class="section section-low-bottom section-low-top"
    :style="`background-color: var(--${vars.backgroundColor}) !important;`"
  >
    <div class="container-default w-container">
      <div class="grid-2-columns _2-col-mbl mg-bottom-48px">
        <h2
          id="w-node-a6c4c924-bd52-e506-6db3-1b47727f27aa-727f27a7"
          class="display-12 mg-bottom-0"
          :style="`color: var(--${vars.titleColor}) !important;`"
        >
          {{ vars.titleText }}
        </h2>
        <div
          id="w-node-_9b92bd97-4ce2-68d3-c9fd-56531d931d48-9e5c1e36"
          data-w-id="9b92bd97-4ce2-68d3-c9fd-56531d931d48"
          style="
            transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
              skew(0deg, 0deg);
            transform-style: preserve-3d;
            opacity: 1;
          "
        >
          <nuxt-link :external="true" :to="vars.buttonLinkUrl" class="btn-primary w-button">{{
            vars.buttonLabelText
          }}</nuxt-link>
        </div>
      </div>
      <div class="inner-container _600px-tablet center">
        <div class="w-dyn-list">
          <div role="list" class="grid-3-columns gap-row-56px _1-col-tablet w-dyn-items">
            <div
              v-for="(blog, index) in blogs"
              :key="`blog-${index}`"
              id="w-node-a6c4c924-bd52-e506-6db3-1b47727f27b2-727f27a7"
              role="listitem"
              class="flex w-dyn-item"
            >
              <theme0-blogs-blog-card :blog="blog" :index="index" :component="component" :variables="vars" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import { BlogsMixin } from '~/units/blogs/mixins/blogs.mixin';
import indexGlobal from '~/mixins/index.global';
import { useBlogsStore } from '~/units/blogs/store';
import { ROUTES } from '~/constants/ui.constants';

export default defineNuxtComponent({
  name: 'LatestNews',

  mixins: [BlogsMixin, indexGlobal, ComponentMixin],

  async setup(props: { component: Component }) {
    const blogsStore = useBlogsStore();
    await blogsStore.fetchBlogsInPage(props.component);
  },

  computed: {
    ROUTES() {
      return ROUTES;
    },
  },
});
</script>
