<template>
  <div class="section vertical-marquee-right-section">
    <div class="container-default position-relative---z-index-1 w-container">
      <div class="lg:grid-2-columns _1-2fr---1fr">
        <div
          id="w-node-beef93c0-e385-cb6a-391d-abcb575b8063-6ab70d60"
          data-w-id="beef93c0-e385-cb6a-391d-abcb575b8063"
          style="
            transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
              skew(0deg, 0deg);
            transform-style: preserve-3d;
            opacity: 1;
          "
        >
          <h2 class="display-2 mg-bottom-12px" :style="`color: var(--${vars.titleColor}) !important;`">
            <strong :style="`color: var(--${vars.titleColor}) !important;`">{{ vars.titleText }} </strong>
          </h2>
          <div class="inner-container _596px">
            <p
              v-for="(item, index) in descriptions"
              :key="index"
              class="mg-bottom-40px"
              :style="`color: var(--${vars.textColor}) !important;`"
            >
              {{ item.description }}
            </p>

            <template v-if="vars.buttons1LabelText">
              <div v-for="(button, index) in buttons" :key="`button-${index}`">
                <nuxt-link
                  :to="button.link"
                  :external="true"
                  class="w-button"
                  :class="`${button.type} ${index === 0 ? 'button-row' : null}`"
                >
                  {{ button.label }}
                </nuxt-link>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div data-w-id="d293adea-abab-7f68-b706-9de0958adf3e" style="opacity: 1" class="vertical-marquee-main-container">
        <div
          id="w-node-_40dae630-59ad-4f53-51fa-51d10714b31c-6ab70d60"
          data-w-id="40dae630-59ad-4f53-51fa-51d10714b31c"
          class="vertical-marquee-column"
          style="
            transform: translate3d(0px, -78.4325px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
              skew(0deg, 0deg);
            transform-style: preserve-3d;
            will-change: transform;
          "
        >
          <div class="vertical-marquee-items-container">
            <img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1fe8c1d694063f38a0b_go-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="Go - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1fe859df6d37b7e509d_python-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="Python - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1fe6a18b695e2b1bd9f_vue-js-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="Vue JS - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1fe2bdcd2a0c30e140e_html5-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="HTML5 - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1ff317ef9552ebd9864_svelte-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="Svelte - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1ff2bdcd20b030e140f_scala-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="Scala - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1fee45e01b72bd745f5_kotlin-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="Kotlin - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            />
          </div>
          <div class="vertical-marquee-items-container">
            <img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1fe8c1d694063f38a0b_go-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="Go - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1fe859df6d37b7e509d_python-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="Python - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1fe6a18b695e2b1bd9f_vue-js-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="Vue JS - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1fe2bdcd2a0c30e140e_html5-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="HTML5 - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1ff317ef9552ebd9864_svelte-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="Svelte - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1ff2bdcd20b030e140f_scala-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="Scala - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1fee45e01b72bd745f5_kotlin-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="Kotlin - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            />
          </div>
        </div>
        <div
          id="w-node-_90abfa6f-4c39-9bf6-4e70-524274e6684b-6ab70d60"
          data-w-id="90abfa6f-4c39-9bf6-4e70-524274e6684b"
          class="vertical-marquee-column"
          style="
            transform: translate3d(0px, -946.764px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
              skew(0deg, 0deg);
            transform-style: preserve-3d;
            will-change: transform;
          "
        >
          <div class="vertical-marquee-items-container">
            <img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1fee2412df983b1c7f8_swift-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="Swift - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a2d72c7f3539f2135e9f_node-js-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="Node JS - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1fe44f1dc047c6abaa3_react-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="React - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1fee2412deb60b1c7f7_angular-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="Angular - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1fee3265c7f492e0c87_javascript-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="Javascript - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1fe8c1d694063f38a0b_go-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="Go - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1ffeae8f4944874ab69_c%2B%2B-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="C++ - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            />
          </div>
          <div class="vertical-marquee-items-container">
            <img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1fee2412df983b1c7f8_swift-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="Swift - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a2d72c7f3539f2135e9f_node-js-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="Node JS - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1fe44f1dc047c6abaa3_react-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="React - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1fee2412deb60b1c7f7_angular-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="Angular - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1fee3265c7f492e0c87_javascript-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="Javascript - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1fe8c1d694063f38a0b_go-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="Go - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1ffeae8f4944874ab69_c%2B%2B-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="C++ - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            />
          </div>
        </div>
        <div
          id="w-node-_96969754-c22a-3a67-73d0-bf1a0bce7f7b-6ab70d60"
          data-w-id="96969754-c22a-3a67-73d0-bf1a0bce7f7b"
          class="vertical-marquee-column"
          style="
            transform: translate3d(0px, -78.4325px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
              skew(0deg, 0deg);
            transform-style: preserve-3d;
            will-change: transform;
          "
        >
          <div class="vertical-marquee-items-container">
            <img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1fe44f1dc2a746abaa2_c-sharp-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="C Sharp - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1fe10406423553d10b7_php-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="PHP - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1ff44f1dc8d056abaa8_ruby-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="Ruby - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1feb9d4ce6a7e992110_css3-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="CSS3 - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1fee2412df983b1c7f8_swift-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="Swift - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1fe10406423553d10b7_php-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="PHP - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1ff317ef9552ebd9864_svelte-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="Svelte - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            />
          </div>
          <div class="vertical-marquee-items-container">
            <img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1fe44f1dc2a746abaa2_c-sharp-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="C Sharp - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1fe10406423553d10b7_php-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="PHP - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1ff44f1dc8d056abaa8_ruby-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="Ruby - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1feb9d4ce6a7e992110_css3-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="CSS3 - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1fee2412df983b1c7f8_swift-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="Swift - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1fe10406423553d10b7_php-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="PHP - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            /><img
              src="https://cdn.prod.website-files.com/633f27fb27319af888b70d5f/6340a1ff317ef9552ebd9864_svelte-marquee-logo-sleek-webflow-ecommerce-template.svg"
              loading="eager"
              alt="Svelte - Sleek X Webflow Template"
              class="shadow-01 border-radius-24px max-w-80px-tablet"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'ScrollingLogos',

  mixins: [ComponentMixin],

  computed: {
    buttons() {
      return this.groupedVariables.buttons;
    },

    descriptions() {
      return this.groupedVariables.descriptions;
    },
  },
});
</script>

<style scoped>
/* Genel animasyonlar */
@keyframes scrollUp {
  0% {
    transform: translate3d(0, 5%, 0);
  }
  100% {
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes scrollDown {
  0% {
    transform: translate3d(0, -60%, 0);
  }
  100% {
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes scrollLeft {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes scrollRight {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}

/* Varsayılan ayarlar */
.vertical-marquee-column {
  animation-duration: 300s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  will-change: transform;
}

.vertical-marquee-column:nth-child(1),
.vertical-marquee-column:nth-child(3) {
  animation-name: scrollUp;
}

.vertical-marquee-column:nth-child(2) {
  animation-name: scrollDown;
}

@media (max-width: 920px) {
  .vertical-marquee-main-container {
    display: flex;
    flex-direction: column;

    overflow: hidden;
  }

  .vertical-marquee-column {
    flex: 1;
  }

  .vertical-marquee-column:nth-child(1),
  .vertical-marquee-column:nth-child(3) {
    animation-name: scrollLeft;
  }

  .vertical-marquee-column:nth-child(2) {
    animation-name: scrollRight;
  }
}
</style>
