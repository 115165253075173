<template>
  <div id="our-values" class="section" :style="`background-color: var(--${vars.backgroundColor}) !important;`">
    <div class="container-default w-container">
      <div
        data-w-id="cb3b4fce-6b95-82f1-c182-6546198a6449"
        class="text-center mg-bottom-40px"
        style="
          transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
            skew(0deg, 0deg);
          transform-style: preserve-3d;
          opacity: 1;
        "
      >
        <h2 class="display-2 color-neutral-100 mg-bottom-8px" :style="`color: var(--${vars.titleColor}) !important;`">
          {{ vars.titleText }}
        </h2>
        <p class="color-neutral-400 mg-bottom-0 center-text" :style="`color: var(--${vars.textColor}) !important;`">
          {{ vars.descriptionText }}
        </p>
      </div>
      <div class="mg-bottom-40px grid-3-columns">
        <div
          v-for="(card, index) in cards"
          :key="`card-${index}`"
          id="w-node-cb3b4fce-6b95-82f1-c182-6546198a6450-198a6447"
          data-w-id="cb3b4fce-6b95-82f1-c182-6546198a6450"
          class="card pd-48px---26px---44px bg-white"
          :style="`
            background-color: var(--${vars.cardBackgroundColor}) !important;
          `"
          style="
            transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
              skew(0deg, 0deg);
            transform-style: preserve-3d;
            opacity: 1;
          "
        >
          <img v-if="card.icon" :src="card.icon" loading="eager" :alt="card.title" class="mg-bottom-24px-mbl tw-mb-2" />
          <h3 class="color-neutral-100 bold600" :style="`color: var(--${vars.titleColor}) !important;`">
            {{ card.title }}
          </h3>
          <p
            class="color-neutral-400 mg-bottom-0"
            :style="`color: var(--${vars.textColor}) !important;`"
            v-html="card.description"
          ></p>
        </div>
      </div>
      <div data-w-id="ddf51907-cdd4-05ef-2076-91b2db615056" class="buttons-row center">
        <div v-for="(button, index) in buttons" :key="`button-${index}`" class="max-md:tw-w-full">
          <nuxt-link
            :to="button.link"
            :external="true"
            class="w-button"
            :class="`${button.type} ${index === 0 ? 'button-row' : ''}`"
          >
            {{ button.label }}
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'CoreValues',

  mixins: [ComponentMixin],

  computed: {
    cards() {
      return this.groupedVariables.cards;
    },

    buttons() {
      return this.groupedVariables.buttons;
    },
  },
});
</script>
