<template>
  <div
    data-w-id="b3a417ba-218f-3d34-cb6a-e0bfa3f58f61"
    data-animation="default"
    data-collapse="medium"
    data-duration="400"
    data-easing="ease"
    data-easing2="ease"
    role="banner"
    class="header-wrapper sticky-top w-nav"
  >
    <div class="container-default z-index-10 w-container">
      <div class="header-content-wrapper iceberg-nav">
        <div class="header-left-side">
          <nuxt-link to="/" :external="true" aria-current="page" class="header-logo-link w-nav-brand w--current">
            <img :src="vars.logoImage" alt="Iceberg Digital" width="122" height="Auto" />
          </nuxt-link>
        </div>
        <div class="header-middle product-middle ls icebergcolor mblmiddle">
          <div class="header-middle">
            <nav role="navigation" class="header-nav-menu-wrapper w-nav-menu">
              <ul role="list" class="header-nav-menu-list">
                <li
                  v-for="(menuGroup, groupIndex) in allMenu[0]"
                  :key="`menuGroupIndex-${groupIndex}`"
                  class="header-nav-list-item left"
                >
                  <div
                    v-if="menuGroup.has_sub_menu"
                    data-hover="true"
                    data-delay="0"
                    class="dropdown-wrapper w-dropdown"
                  >
                    <div class="dropdown-toggle w-dropdown-toggle">
                      <div>{{ menuGroup.name }}</div>
                      <div class="line-rounded-icon dropdown-arrow"></div>
                    </div>
                    <nav class="dropdown-column-wrapper center w-dropdown-list">
                      <div class="dropdown-pd pd-48px">
                        <div class="w-layout-grid grid-1-column">
                          <div>
                            <div class="grid-1-column">
                              <div class="w-layout-grid grid-1-column dropdown-link-column">
                                <a
                                  v-for="(subMenu, subMenuIndex) in menuGroup.sub_menus"
                                  :key="`subMenu-${subMenuIndex}`"
                                  :href="subMenu.url"
                                  :target="subMenu.is_open_new_tab ? '_blank' : ''"
                                  class="dropdown-link product w-dropdown-link"
                                >
                                  {{ subMenu.name }}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </nav>
                  </div>
                  <a
                    v-else
                    :href="menuGroup.url"
                    :target="menuGroup.is_open_new_tab ? '_blank' : ''"
                    class="header-nav-link w-nav-link"
                  >
                    {{ menuGroup.name }}
                  </a>
                </li>
                <li class="header-nav-list-item show-in-tablet">
                  <a href="contact.html" class="btn-primary small white w-button">Contact us</a>
                </li>
              </ul>
            </nav>
          </div>
          <div
            class="w-nav-overlay lg:tw-hidden"
            data-wf-ignore=""
            id="w-nav-overlay-0"
            :class="{ 'menu-open': menuVisible }"
            :style="{ display: menuVisible ? 'block' : 'none', height: menuVisible ? '100vh' : '0' }"
          >
            <nav
              role="navigation"
              class="header-nav-menu-wrapper w-nav-menu"
              style="
                transition:
                  all,
                  transform 400ms;
                transform: translateY(0px) translateX(0px);
              "
              data-nav-menu-open=""
            >
              <ul role="list" class="header-nav-menu-list">
                <li
                  v-for="(menuGroup, groupIndex) in allMenu[0]"
                  :key="`menuGroupIndex-${groupIndex}`"
                  class="header-nav-list-item left"
                >
                  <div
                    v-if="menuGroup.has_sub_menu"
                    data-hover="true"
                    data-delay="0"
                    class="dropdown-wrapper w-dropdown"
                  >
                    <div class="dropdown-toggle w-dropdown-toggle">
                      <div>{{ menuGroup.name }}</div>
                      <div class="line-rounded-icon dropdown-arrow"></div>
                    </div>
                    <nav class="dropdown-column-wrapper center w-dropdown-list">
                      <div class="dropdown-pd pd-48px">
                        <div class="w-layout-grid grid-1-column">
                          <div>
                            <div class="grid-1-column">
                              <div class="w-layout-grid grid-1-column dropdown-link-column">
                                <a
                                  v-for="(subMenu, subMenuIndex) in menuGroup.sub_menus"
                                  :key="`subMenu-${subMenuIndex}`"
                                  :href="subMenu.url"
                                  :target="subMenu.is_open_new_tab ? '_blank' : ''"
                                  class="dropdown-link product w-dropdown-link"
                                >
                                  {{ subMenu.name }}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </nav>
                  </div>
                  <a
                    v-else
                    :href="menuGroup.url"
                    :target="menuGroup.is_open_new_tab ? '_blank' : ''"
                    class="header-nav-link w-nav-link"
                  >
                    {{ menuGroup.name }}
                  </a>
                </li>
                <li class="header-nav-list-item show-in-tablet">
                  <a href="/contact" class="btn-primary small white w-button">Contact us</a>
                </li>
              </ul>
            </nav>
          </div>
          <div class="header-right-side">
            <!-- <div class="hamburger-menu-wrapper w-nav-button" @click="toggleMenu">
              <div class="hamburger-menu-bar top"></div>
              <div class="hamburger-menu-bar bottom"></div>
            </div> -->
            <nuxt-link
              :to="vars.buttonLinkUrl"
              class="btn-primary small white w-button !tw-m-0 lg:tw-hidden"
              :class="`${vars.buttonTypeText}`"
              ><span> {{ vars.buttonLabelText }}</span></nuxt-link
            >

            <div class="header-btn-hidden-on-mobile-wrapper">
              <nuxt-link
                :to="vars.buttonLinkUrl"
                :external="true"
                class="btn-primary small white w-button"
                :class="`${vars.buttonTypeText}`"
                ><span> {{ vars.buttonLabelText }}</span></nuxt-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import { MenuTypes, ViewTypes } from '~/constants/ui.constants';
import { ComponentMixin } from '~/mixins/component.mixin';
import { useWebsiteStore } from '~/units/website/store';
import { useCoreStore } from '~/units/core/store';

type Menu = {};

export default defineNuxtComponent({
  name: 'Theme0BaseHeaderOne',

  mixins: [ComponentMixin],

  props: {
    component: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      ViewTypes,
      menuVisible: false,
      isScrolled: false,
      openSubMenu: null,
      variants: {
        HEADER_1: 'HEADER_1',
        HEADER_2: 'HEADER_2',
      },
      isActive: false,
      scrollThreshold: 350,
      isLightMode: false,
      isMenuOpen: false,
      scrollPosition: 0,
    };
  },

  computed: {
    ...mapState(useWebsiteStore, ['headerMenuItems']),
    ...mapState(useCoreStore, ['isAnythingOnTopVisible']),

    headerMenu() {
      return this.headerMenuItems as Menu[];
    },

    routeUrl() {
      const route = useRoute();
      return route.path;
    },

    allMenu() {
      const period = 9;
      const columnsCount = Math.ceil(this.columnOneMenu.length / period);
      const groups = [];

      for (let i = 0; i < columnsCount; i++) {
        groups[i] = [];
        for (let p = 0; p < period; p++) {
          const index = period * i + p;
          if (this.columnOneMenu[index]) {
            groups[i].push(this.columnOneMenu[index]);
          }
        }
      }

      return groups;
    },

    columnOneMenu() {
      const content = this.component.variables['column_one_content-text'];
      if (content === MenuTypes.HEADER_MENU) {
        return this.headerMenu;
      }
      return [];
    },
  },

  methods: {
    toggleSubMenu(index) {
      this.openSubMenu = this.openSubMenu === index ? null : index;
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
      document.body.style.overflow = this.menuVisible ? 'hidden' : 'auto'; // Sayfa kaydırmayı engelle
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 0;
    },
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();

    if (this.menuVisible) {
      document.body.classList.add('no-scroll');
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
    document.body.classList.remove('no-scroll');
  },
});
</script>

<style scoped>
.w-nav-overlay {
  transition: all 0.4s ease;
  overflow: hidden;
}

.menu-open {
  height: 100vh;
  display: block;
}
</style>
