import { NitroFetchOptions, NitroFetchRequest } from 'nitropack';
import { useCoreStore } from '~/units/core/store';
import type { ApiCallerOpts } from '~/hydration/types';

export class NetworkCaller {
  private readonly opts: ApiCallerOpts = {};

  constructor(opts: ApiCallerOpts = {}) {
    if (opts) {
      this.opts = opts;
    }
  }

  /**
   * @description this is function for calling network on client/browser side.
   * @param request
   * @param nitroFetchOptions
   */
  async call(request: NitroFetchRequest, nitroFetchOptions: NitroFetchOptions) {
    try {
      const requestOptions = nitroFetchOptions ? nitroFetchOptions : {};

      if (this.opts.isBotCheckEnabled) {
        const coreStore = useCoreStore();
        const recaptchaClientToken = await coreStore.getRecaptchaInstantToken();

        const headers = requestOptions.headers ? requestOptions.headers : {};

        requestOptions.headers = {
          ...headers,
          'x-recaptcha-token': recaptchaClientToken.token,
        };
      }

      const response = await $fetch(request, {
        ...requestOptions,
        parseResponse: JSON.parse,
      });

      return response.result;
    } catch (e) {
      if (e.response.status === 401) {
        const coreStore = useCoreStore();
        await coreStore.refreshToken();

        const response = await $fetch(request, {
          ...(opts ?? {}),
          headers: {
            ...(opts.headers || {}),
            'x-app-access-token': coreStore.requestConfig?.app_token?.access_token,
          },
          parseResponse: JSON.parse,
        });

        return response.result;
      }
      throw e;
    }
  }
}
