<template>
  <component
    :is="component.component.name"
    v-for="(component, index) in specificComponents"
    :key="`dynamicComponent-${index}`"
    :component="component"
  ></component>
</template>

<script lang="ts">
// layouts
import FooterOne from '~/components/theme0/base/footer/FooterOne.vue';
import HeaderOne from '~/components/theme0/base/header/HeaderOne.vue';
import ScrollingLogos from '~/components/theme0/components/ScrollingLogos.vue';
import ContentSection from '~/components/theme0/components/ContentSection.vue';
import InfoBox from '~/components/theme0/components/InfoBox.vue';
import OurAwards from '~/components/theme0/components/OurAwards.vue';
import Integrations from '~/components/theme0/components/Integrations.vue';
import Features from '~/components/theme0/components/Features.vue';
import BrandBanner from '~/components/theme0/components/BrandBanner.vue';
import VideoSlider from '~/components/theme0/components/VideoSlider.vue';
import FAQs from '~/components/theme0/components/FAQs.vue';
import Banner from '~/components/theme0/components/Banner.vue';
import HeroSection from '~/components/theme0/components/HeroSection.vue';
import Achievements from '~/components/theme0/components/Achievements.vue';
import InfoTabs from '~/components/theme0/components/InfoTabs.vue';
import OurBrand from '~/components/theme0/components/OurBrand.vue';
import CoreValues from '~/components/theme0/components/CoreValues.vue';
import PersonCard from '~/components/theme0/components/PersonCard.vue';
import Press from '~/components/theme0/components/Press.vue';
import LatestNews from '~/components/theme0/components/LatestNews.vue';
import IntroSection from '~/components/theme0/components/IntroSection.vue';
import FormCard from '~/components/theme0/components/FormCard.vue';
import NotFoundPage from '~/components/theme0/components/NotFoundPage.vue';
import Benefits from '~/components/theme0/components/Benefits.vue';
import ContactForm from '~/components/theme0/components/ContactForm.vue';
import GetInTouch from '~/components/theme0/components/GetInTouch.vue';
import Overview from '~/components/theme0/components/Overview.vue';
import OutroSection from '~/components/theme0/components/OutroSection.vue';
import CareerIntro from '~/components/theme0/components/CareerIntro.vue';
import Gallery from '~/components/theme0/components/Gallery.vue';

import { Theme0BlogsPage, Theme0BlogsBlogDetail } from '#components';

export default defineNuxtComponent({
  props: {
    specificComponents: {
      required: false,
      default: () => [],
    },
  },

  components: {
    FooterOne,
    Gallery,
    HeaderOne,
    GetInTouch,
    CareerIntro,
    ScrollingLogos,
    OutroSection,
    Overview,
    ContentSection,
    ContactForm,
    InfoBox,
    OurAwards,
    Integrations,
    Features,
    BrandBanner,
    VideoSlider,
    FAQs,
    Banner,
    HeroSection,
    Achievements,
    InfoTabs,
    OurBrand,
    CoreValues,
    PersonCard,
    Press,
    LatestNews,
    IntroSection,
    BlogsPage: Theme0BlogsPage,
    BlogDetail: Theme0BlogsBlogDetail,
    FormCard,
    NotFoundPage,
    Benefits,
  },
});
</script>
