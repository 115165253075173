<template>
  <section
    v-if="variants.v1 === variant"
    class="n-section-primary tw-relative"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
  >
    <div
      class="tw-mb-10 tw-flex tw-flex-col tw-items-start tw-gap-3 tw-self-start tw-text-start md:tw-items-center md:tw-self-center md:tw-text-center lg:tw-mb-16"
    >
      <small v-if="vars.altTitleText"> {{ vars.altTitleText }}</small>
      <h2>{{ vars.titleText }}</h2>
    </div>
    <div v-if="isBlogsLoading">
      <loader loader-color="var(--c-primary)" />
    </div>
    <div class="tw-relative tw-w-full">
      <Swiper
        :modules="[SwiperPagination]"
        :slides-per-view="3"
        :space-between="30"
        effect="fade"
        :clickable="true"
        :breakpoints="{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 24,
          },
          1336: {
            slidesPerView: 3,
            spaceBetween: 24,
          },
        }"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        class="tw-w-full"
      >
        <SwiperSlide
          v-for="(blog, index) in blogs"
          :key="`blog-${index}`"
          class="tw-flex tw-items-center tw-justify-center"
          :delay="index * 250"
        >
          <Theme6BlogsBlogCard :blog="blog" :index="index" :component="component" />
        </SwiperSlide>
      </Swiper>
      <LibrarySliderPagination
        :swiper="swiper"
        variant="v4"
        :can-paginate-back="canPaginateBack"
        :can-paginate-next="canPaginateNext"
      />
    </div>
    <nuxt-link
      v-if="vars.buttonLabelText"
      :external="true"
      :class="`n-${vars.buttonTypeText} n-btn tw-mt-10 tw-self-center`"
      :to="ROUTES.BLOGS"
    >
      <div class="n-btn-container">
        <div class="n-btn-group">
          <div class="n-btn-t1">{{ vars.buttonLabelText }}</div>
          <div class="n-btn-t2">{{ vars.buttonLabelText }}</div>
        </div>
      </div>
    </nuxt-link>
  </section>

  <section
    v-if="variants.v2 === variant"
    class="n-section-primary tw-relative"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
  >
    <div class="tw-mb-8 tw-flex tw-flex-col tw-items-center tw-gap-3 tw-self-center tw-text-center lg:tw-mb-12">
      <h2>{{ vars.titleText }}</h2>
    </div>
    <div v-if="isBlogsLoading">
      <loader loader-color="var(--c-primary)" />
    </div>
    <div class="tw-relative tw-w-full">
      <Swiper
        :modules="[SwiperPagination]"
        :slides-per-view="3"
        :space-between="30"
        effect="fade"
        :loop="true"
        :clickable="true"
        :breakpoints="{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 32,
          },
          1336: {
            slidesPerView: 3,
            spaceBetween: 48,
          },
        }"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        class="tw-w-full"
      >
        <SwiperSlide
          v-for="(blog, index) in blogs"
          :key="`blog-${index}`"
          class="tw-flex tw-items-center tw-justify-center"
          :delay="index * 250"
        >
          <Theme6BlogsBlogCard :blog="blog" :index="index" :component="component" />
        </SwiperSlide>
      </Swiper>
      <LibrarySliderPagination
        :swiper="swiper"
        variant="v5"
        :can-paginate-back="canPaginateBack"
        :can-paginate-next="canPaginateNext"
      />
    </div>
    <nuxt-link
      v-if="vars.buttonTypeText"
      :external="true"
      :class="`n-${vars.buttonTypeText} n-btn tw-mt-6 tw-self-center`"
      :to="ROUTES.BLOGS"
    >
      <div class="n-btn-container">
        <div class="n-btn-group">
          <div class="n-btn-t1">{{ vars.buttonLabelText }}</div>
          <div class="n-btn-t2">{{ vars.buttonLabelText }}</div>
        </div>
      </div>
    </nuxt-link>
  </section>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import type { Component } from '~/types';
import indexGlobal from '~/mixins/index.global';
import { BlogsMixin } from '~/units/blogs/mixins/blogs.mixin';
import { ComponentMixin } from '~/mixins/component.mixin';
import { useBlogsStore } from '~/units/blogs/store';
import { useWebsiteStore } from '~/units/website/store';
import { ROUTES } from '~/constants/ui.constants';
import Loader from '~/components/common/Loader.vue';
export default defineNuxtComponent({
  name: 'Theme6OurBlog',

  mixins: [BlogsMixin, indexGlobal, ComponentMixin],

  components: {
    Loader,
  },

  data() {
    return {
      swiper: null,
      canPaginateBack: true,
      canPaginateNext: true,

      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  async setup(props: { component: Component }) {
    const blogsStore = useBlogsStore();
    await blogsStore.fetchBlogsInPage(props.component);
  },

  computed: {
    ...mapState(useWebsiteStore, ['sidebarComponents']),
    ROUTES() {
      return ROUTES;
    },
  },

  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },

    onSlideChange() {
      if (!this.swiper) return;
      const isAtStart = this.swiper.isBeginning;
      const isAtEnd = this.swiper.isEnd;
      if (isAtStart) this.canPaginateBack = false;
      else this.canPaginateBack = true;
      if (isAtEnd) this.canPaginateNext = false;
      else this.canPaginateNext = true;
    },
  },
  mounted() {
    this.onSlideChange();
  },
});
</script>
