<template>
  <div
    id="latest-events"
    class="section bg-neutral-100 section-low-top section-low-bottom"
    :style="`background-color: var(--${vars.backgroundColor}) !important;`"
  >
    <div class="container-default w-container">
      <div
        data-w-id="1513f889-72e2-d6f4-9ea9-f91e7e0e4beb"
        style="
          transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
            skew(0deg, 0deg);
          opacity: 1;
          transform-style: preserve-3d;
        "
        class="text-center mg-bottom-40px"
      >
        <div class="inner-container _522px center">
          <h3 class="display-2 mg-bottom-8px">
            <strong>{{ vars.titleText }}</strong>
          </h3>
          <p class="paragraph-4 center-text">
            {{ vars.descriptionText }}
          </p>
          <nuxt-link
            v-if="vars.buttonLinkUrl"
            :to="vars.buttonLinkUrl"
            class="w-button"
            :class="`${vars.buttonTypeText}`"
            >{{ vars.buttonLabelText }}</nuxt-link
          >
        </div>
      </div>
      <section class="rl_section_gallery21" :style="`background-color: var(--${vars.backgroundColor}) !important;`">
        <div class="rl-padding-global-2">
          <div class="rl-container-large-2">
            <div class="div-block-13">
              <div class="rl_gallery21_component">
                <div class="rl_gallery21_heading-wrapper"></div>
                <div
                  data-delay="4000"
                  data-animation="slide"
                  class="rl_gallery21_slider pb-0 mbl-pb-5 w-slider"
                  data-autoplay="false"
                  data-easing="ease"
                  data-hide-arrows="false"
                  data-disable-swipe="true"
                  data-autoplay-limit="0"
                  data-nav-spacing="3"
                  data-duration="500"
                  data-infinite="true"
                  id="gallery13_slider"
                  role="region"
                  aria-label="carousel"
                >
                  <div class="rl_gallery21_mask w-slider-mask" id="w-slider-mask-0">
                    <div
                      class="rl_gallery21_slide w-slide"
                      aria-label="1 of 8"
                      role="group"
                      style="
                        transition:
                          all,
                          transform 500ms;
                        transform: translateX(-549px);
                        opacity: 1;
                      "
                      aria-hidden="true"
                    >
                      <a
                        href="#"
                        class="rl_gallery21_lightbox-link w-inline-block w-lightbox"
                        aria-label="open lightbox"
                        aria-haspopup="dialog"
                        tabindex="-1"
                        aria-hidden="true"
                        ><div class="rl_gallery21_image-wrapper pt-80" aria-hidden="true">
                          <img
                            src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/653b91888b7f232d78fb84cf_tr-team.jpeg"
                            loading="lazy"
                            sizes="(max-width: 479px) 79vw, (max-width: 767px) 82vw, (max-width: 1439px) 40vw, 517px"
                            srcset="
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/653b91888b7f232d78fb84cf_tr-team-p-500.jpeg   500w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/653b91888b7f232d78fb84cf_tr-team-p-800.jpeg   800w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/653b91888b7f232d78fb84cf_tr-team-p-1080.jpeg 1080w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/653b91888b7f232d78fb84cf_tr-team-p-1600.jpeg 1600w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/653b91888b7f232d78fb84cf_tr-team.jpeg        1988w
                            "
                            alt="Iceberg Digital"
                            class="rl_gallery21_image h-400"
                            aria-hidden="true"
                          />
                        </div>
                        <!-- 1 image here -->
                      </a>
                    </div>
                    <div
                      class="rl_gallery21_slide w-slide"
                      aria-label="2 of 8"
                      role="group"
                      style="
                        transition:
                          all,
                          transform 500ms;
                        transform: translateX(-549px);
                        opacity: 1;
                      "
                    >
                      <a
                        href="#"
                        class="rl_gallery21_lightbox-link w-inline-block w-lightbox"
                        aria-label="open lightbox"
                        aria-haspopup="dialog"
                        ><div class="rl_gallery21_image-wrapper">
                          <img
                            src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6576e7c10f4d487c491aa0fa_IMG_4320-2-min.webp"
                            loading="lazy"
                            sizes="(max-width: 479px) 79vw, (max-width: 767px) 82vw, (max-width: 1439px) 40vw, 517px"
                            srcset="
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6576e7c10f4d487c491aa0fa_IMG_4320-2-min-p-500.webp   500w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6576e7c10f4d487c491aa0fa_IMG_4320-2-min-p-800.webp   800w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6576e7c10f4d487c491aa0fa_IMG_4320-2-min-p-1080.webp 1080w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6576e7c10f4d487c491aa0fa_IMG_4320-2-min-p-1600.webp 1600w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6576e7c10f4d487c491aa0fa_IMG_4320-2-min-p-2000.webp 2000w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6576e7c10f4d487c491aa0fa_IMG_4320-2-min-p-2600.webp 2600w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6576e7c10f4d487c491aa0fa_IMG_4320-2-min-p-3200.webp 3200w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6576e7c10f4d487c491aa0fa_IMG_4320-2-min.webp        6240w
                            "
                            alt="Iceberg Digital"
                            class="rl_gallery21_image h-400"
                          />
                        </div>
                        <!-- 1 image here -->
                      </a>
                    </div>
                    <div
                      class="rl_gallery21_slide w-slide"
                      aria-label="3 of 8"
                      role="group"
                      style="
                        transition:
                          all,
                          transform 500ms;
                        transform: translateX(-549px);
                        opacity: 1;
                      "
                      aria-hidden="true"
                    >
                      <a
                        href="#"
                        class="rl_gallery21_lightbox-link w-inline-block w-lightbox"
                        aria-label="open lightbox"
                        aria-haspopup="dialog"
                        tabindex="-1"
                        aria-hidden="true"
                        ><div class="rl_gallery21_image-wrapper" aria-hidden="true">
                          <img
                            src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/65798450d6e2ff40ddadd9cd_20211005_153429.jpg"
                            loading="lazy"
                            sizes="(max-width: 479px) 79vw, (max-width: 767px) 82vw, (max-width: 1439px) 40vw, 517px"
                            srcset="
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/65798450d6e2ff40ddadd9cd_20211005_153429-p-500.jpg   500w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/65798450d6e2ff40ddadd9cd_20211005_153429-p-800.jpg   800w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/65798450d6e2ff40ddadd9cd_20211005_153429-p-1080.jpg 1080w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/65798450d6e2ff40ddadd9cd_20211005_153429-p-1600.jpg 1600w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/65798450d6e2ff40ddadd9cd_20211005_153429-p-2000.jpg 2000w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/65798450d6e2ff40ddadd9cd_20211005_153429-p-2600.jpg 2600w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/65798450d6e2ff40ddadd9cd_20211005_153429-p-3200.jpg 3200w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/65798450d6e2ff40ddadd9cd_20211005_153429.jpg        4128w
                            "
                            alt="8 Members Club"
                            class="rl_gallery21_image h-400"
                            aria-hidden="true"
                          />
                        </div>
                        <!-- 1 image here -->
                      </a>
                    </div>
                    <div
                      class="rl_gallery21_slide w-slide"
                      aria-label="4 of 8"
                      role="group"
                      aria-hidden="true"
                      style="
                        transition:
                          all,
                          transform 500ms;
                        transform: translateX(-549px);
                        opacity: 1;
                      "
                    >
                      <a
                        href="#"
                        class="rl_gallery21_lightbox-link w-inline-block w-lightbox"
                        tabindex="-1"
                        aria-hidden="true"
                        aria-label="open lightbox"
                        aria-haspopup="dialog"
                        ><div class="rl_gallery21_image-wrapper" aria-hidden="true">
                          <img
                            src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6579849e4812a767cea6c8ce_DSC_0016-min.JPG"
                            loading="lazy"
                            sizes="(max-width: 479px) 79vw, (max-width: 767px) 82vw, (max-width: 1439px) 40vw, 517px"
                            srcset="
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6579849e4812a767cea6c8ce_DSC_0016-min-p-500.jpg   500w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6579849e4812a767cea6c8ce_DSC_0016-min-p-800.jpg   800w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6579849e4812a767cea6c8ce_DSC_0016-min-p-1080.jpg 1080w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6579849e4812a767cea6c8ce_DSC_0016-min-p-1600.jpg 1600w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6579849e4812a767cea6c8ce_DSC_0016-min-p-2000.jpg 2000w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6579849e4812a767cea6c8ce_DSC_0016-min-p-2600.jpg 2600w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6579849e4812a767cea6c8ce_DSC_0016-min-p-3200.jpg 3200w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6579849e4812a767cea6c8ce_DSC_0016-min.JPG        6016w
                            "
                            alt="8 Members Club"
                            class="rl_gallery21_image h-400"
                            aria-hidden="true"
                          />
                        </div>
                        <!-- 1 image here -->
                      </a>
                    </div>
                    <div
                      class="rl_gallery21_slide w-slide"
                      aria-label="5 of 8"
                      role="group"
                      aria-hidden="true"
                      style="
                        transition:
                          all,
                          transform 500ms;
                        transform: translateX(-549px);
                        opacity: 1;
                      "
                    >
                      <a
                        href="#"
                        class="rl_gallery21_lightbox-link w-inline-block w-lightbox"
                        tabindex="-1"
                        aria-hidden="true"
                        aria-label="open lightbox"
                        aria-haspopup="dialog"
                        ><div class="rl_gallery21_image-wrapper" aria-hidden="true">
                          <img
                            src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6576e7c02d83e2dbd51a18c8_IMG_4033-min.webp"
                            loading="lazy"
                            sizes="(max-width: 479px) 79vw, (max-width: 767px) 82vw, (max-width: 1439px) 40vw, 517px"
                            srcset="
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6576e7c02d83e2dbd51a18c8_IMG_4033-min-p-500.webp   500w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6576e7c02d83e2dbd51a18c8_IMG_4033-min-p-800.webp   800w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6576e7c02d83e2dbd51a18c8_IMG_4033-min-p-1080.webp 1080w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6576e7c02d83e2dbd51a18c8_IMG_4033-min-p-1600.webp 1600w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6576e7c02d83e2dbd51a18c8_IMG_4033-min-p-2000.webp 2000w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6576e7c02d83e2dbd51a18c8_IMG_4033-min-p-2600.webp 2600w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6576e7c02d83e2dbd51a18c8_IMG_4033-min-p-3200.webp 3200w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6576e7c02d83e2dbd51a18c8_IMG_4033-min.webp        6240w
                            "
                            alt="8 Members Club"
                            class="rl_gallery21_image h-400"
                            aria-hidden="true"
                          />
                        </div>
                        <!-- 1 image here -->
                      </a>
                    </div>
                    <div
                      class="rl_gallery21_slide w-slide"
                      aria-label="6 of 8"
                      role="group"
                      aria-hidden="true"
                      style="
                        transition:
                          all,
                          transform 500ms;
                        transform: translateX(-549px);
                        opacity: 1;
                      "
                    >
                      <a
                        href="#"
                        class="rl_gallery21_lightbox-link w-inline-block w-lightbox"
                        tabindex="-1"
                        aria-hidden="true"
                        aria-label="open lightbox"
                        aria-haspopup="dialog"
                        ><div class="rl_gallery21_image-wrapper" aria-hidden="true">
                          <img
                            src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6576e7c129004f08117572d2_IMG_8452-min.webp"
                            loading="lazy"
                            sizes="(max-width: 479px) 79vw, (max-width: 767px) 82vw, (max-width: 1439px) 40vw, 517px"
                            srcset="
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6576e7c129004f08117572d2_IMG_8452-min-p-500.webp   500w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6576e7c129004f08117572d2_IMG_8452-min-p-800.webp   800w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6576e7c129004f08117572d2_IMG_8452-min-p-1080.webp 1080w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6576e7c129004f08117572d2_IMG_8452-min-p-1600.webp 1600w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6576e7c129004f08117572d2_IMG_8452-min-p-2000.webp 2000w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6576e7c129004f08117572d2_IMG_8452-min-p-2600.webp 2600w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6576e7c129004f08117572d2_IMG_8452-min-p-3200.webp 3200w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6576e7c129004f08117572d2_IMG_8452-min.webp        6240w
                            "
                            alt="8 Members Club"
                            class="rl_gallery21_image h-400"
                            aria-hidden="true"
                          />
                        </div>
                        <!-- 1 image here -->
                      </a>
                    </div>
                    <div
                      class="rl_gallery21_slide w-slide"
                      aria-label="7 of 8"
                      role="group"
                      aria-hidden="true"
                      style="
                        transition:
                          all,
                          transform 500ms;
                        transform: translateX(-549px);
                        opacity: 1;
                      "
                    >
                      <a
                        href="#"
                        class="rl_gallery21_lightbox-link w-inline-block w-lightbox"
                        tabindex="-1"
                        aria-hidden="true"
                        aria-label="open lightbox"
                        aria-haspopup="dialog"
                        ><div class="rl_gallery21_image-wrapper" aria-hidden="true">
                          <img
                            src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480b876f46ff8702e0fb54f_PHOTO-2022-10-07-17-29-43.jpg"
                            loading="lazy"
                            sizes="(max-width: 479px) 79vw, (max-width: 767px) 82vw, (max-width: 1439px) 40vw, 517px"
                            srcset="
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480b876f46ff8702e0fb54f_PHOTO-2022-10-07-17-29-43-p-500.jpg   500w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480b876f46ff8702e0fb54f_PHOTO-2022-10-07-17-29-43-p-800.jpg   800w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480b876f46ff8702e0fb54f_PHOTO-2022-10-07-17-29-43-p-1080.jpg 1080w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480b876f46ff8702e0fb54f_PHOTO-2022-10-07-17-29-43.jpg        1600w
                            "
                            alt="8 Members Club"
                            class="rl_gallery21_image h-400"
                            aria-hidden="true"
                          />
                        </div>
                        <!-- 1 image here -->
                      </a>
                    </div>
                    <div
                      class="rl_gallery21_slide w-slide"
                      aria-label="8 of 8"
                      role="group"
                      aria-hidden="true"
                      style="
                        transition:
                          all,
                          transform 500ms;
                        transform: translateX(-549px);
                        opacity: 1;
                      "
                    >
                      <a
                        href="#"
                        class="rl_gallery21_lightbox-link w-inline-block w-lightbox"
                        tabindex="-1"
                        aria-hidden="true"
                        aria-label="open lightbox"
                        aria-haspopup="dialog"
                        ><div class="rl_gallery21_image-wrapper" aria-hidden="true">
                          <img
                            src="https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480854508a7fb998998da00_Uk-Team-2.png"
                            loading="lazy"
                            sizes="(max-width: 479px) 79vw, (max-width: 767px) 82vw, (max-width: 1439px) 40vw, 517px"
                            srcset="
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480854508a7fb998998da00_Uk-Team-2-p-500.png 500w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480854508a7fb998998da00_Uk-Team-2-p-800.png 800w,
                              https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/6480854508a7fb998998da00_Uk-Team-2.png       852w
                            "
                            alt="8 Members Club"
                            class="rl_gallery21_image h-400"
                            aria-hidden="true"
                          />
                        </div>
                        <!-- 1 image here -->
                      </a>
                    </div>
                    <div aria-live="off" aria-atomic="true" class="w-slider-aria-label" data-wf-ignore="">
                      Slide 2 of 8.
                    </div>
                  </div>
                  <div
                    class="rl_gallery21_arrow-left slider-btn-dark w-slider-arrow-left"
                    role="button"
                    tabindex="0"
                    aria-controls="w-slider-mask-0"
                    aria-label="previous slide"
                  >
                    <div class="rl_gallery21_arrow-icon w-embed">
                      <svg
                        width=" 100%"
                        height=" 100%"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M5.81066 12.7499L12.5303 19.4696L11.4697 20.5303L2.93934 11.9999L11.4697 3.46961L12.5303 4.53027L5.81066 11.2499L20 11.2499L20 12.7499L5.81066 12.7499Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <div
                    class="rl_gallery21_arrow-right slider-btn-dark w-slider-arrow-right"
                    role="button"
                    tabindex="0"
                    aria-controls="w-slider-mask-0"
                    aria-label="next slide"
                  >
                    <div class="rl_gallery21_arrow-icon w-embed">
                      <svg
                        width=" 100%"
                        height=" 100%"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M18.1893 11.2501L11.4697 4.53039L12.5303 3.46973L21.0607 12.0001L12.5303 20.5304L11.4697 19.4697L18.1893 12.7501H4V11.2501H18.1893Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'Gallery',

  mixins: [ComponentMixin],

  computed: {
    photos() {
      return this.groupedVariables.photos;
    },
  },
});
</script>
