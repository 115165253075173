<template>
  <section class="n-section-primary tw-mt-10">
    <div
      v-if="vars.titleText"
      class="tw-flex tw-h-[25vh] tw-w-screen tw-items-center tw-justify-center tw-text-center"
      :style="`background-color: var(--c-${vars.backgroundColor})`"
    >
      <h3 :style="`color: ${vars.textColor};`">{{ vars.titleText }}</h3>
    </div>
    <div
      v-if="vars.cookiePolicyHtml"
      class="custom-cookie-policy tw-w-full tw-overflow-hidden"
      v-html="vars.cookiePolicyHtml"
    ></div>
  </section>
</template>
<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'CustomCookiePolicy',

  mixins: [ComponentMixin],
});
</script>
<style scoped>
/* Genel stil */

.custom-cookie-policy::v-deep(h1),
.custom-cookie-policy::v-deep(h2),
.custom-cookie-policy::v-deep(h3),
.custom-cookie-policy::v-deep(h4),
.custom-cookie-policy::v-deep(h5),
.custom-cookie-policy::v-deep(h6) {
  font-weight: bold;
  margin: 1em 0 !important;
  color: #000;
}

.custom-cookie-policy::v-deep(h2) {
  font-size: 1.5rem; /* Mobil uyumu için */
}

.custom-cookie-policy::v-deep(p) {
  font-size: 1rem;
  margin: 1em 0;
  line-height: 1.6;
}

.custom-cookie-policy::v-deep(.inner) {
  width: 95%;
  max-width: 390px;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .custom-cookie-policy::v-deep(.inner) {
    max-width: 1280px;
  }
}

.custom-cookie-policy::v-deep(a) {
  padding: unset !important;
}

.custom-cookie-policy::v-deep(a:hover) {
  color: var(--c-secondary);
}

.custom-cookie-policy::v-deep(table) {
  width: 100% !important;
  border-collapse: collapse;
  margin-bottom: 20px;
  font-size: 0.85rem;
  border-spacing: 0px;
  overflow-x: auto;
  display: block;
}

.custom-cookie-policy::v-deep(th),
.custom-cookie-policy::v-deep(td) {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.custom-cookie-policy::v-deep(th) {
  background-color: #f8f9fa;
  font-weight: bold;
  color: #333;
}

.custom-cookie-policy::v-deep(tr:nth-child(even)) {
  background-color: #f2f2f2;
}

.custom-cookie-policy::v-deep(table tr td) {
  word-break: normal;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(0, 0, 0);
  border-image: initial;
  padding: 5px;
}

.custom-cookie-policy::v-deep(ul),
.custom-cookie-policy::v-deep(ol) {
  margin: 0 0 15px 20px;
}

.custom-cookie-policy::v-deep(li) {
  margin-bottom: 5px;
}
</style>
