<template>
  <div class="section section-low-top section-low-bottom">
    <div class="container-default w-container">
      <div class="card top-section-dark-form-right background">
        <div class="grid-2-columns _1fr---1-25fr rl-padding-global padding-top-bottomm-5">
          <div id="w-node-_42fe7e86-e85a-6336-797c-d5e3ec4ad26a-ec4ad266" class="div-block-16">
            <h2 class="display-1 color-neutral-100">{{ vars.titleText }}</h2>
            <p id="w-node-_42fe7e86-e85a-6336-797c-d5e3ec4ad26d-ec4ad266" class="color-neutral-400 mobile-hide">
              {{ vars.descriptionText }}
            </p>
          </div>
          <div id="w-node-_508abc40-0cd1-c2ba-7489-98f7d725d49e-d725d49e" class="form-block w-form">
            <library-form
              v-if="form"
              :form="form"
              version="theme0"
              :isFormNameVisible="false"
              :component="component"
            ></library-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'FormCard',

  mixins: [ComponentMixin],

  data() {
    return {
      form: null,
    };
  },

  created() {
    this.form = this.parseFormV2(this.vars.cardForm);
  },
});
</script>
