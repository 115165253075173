export { ImageResizer } from './imageResizer.plugin';

export const currencyFormat = (num: number): string => {
  if (num) return `£${num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
  return '£0';
};

export const sleep = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay));

export const getPercentage = (percent: number, total: number) => {
  if (isNaN(percent) || isNaN(total)) {
    return 0;
  }
  return +((percent / total) * 100).toFixed(3);
};

export function calculatePercentageValue(percentage: number, totalValue: number) {
  return (percentage / 100) * totalValue;
}

export const getIdOfYoutubeVideo = (url: string) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
};

export function capitalizeFirstLetters(str) {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export const produceKey = () => Math.random().toString(36).substr(2, 9);
