<template>
  <div class="section section-low-bottom section-low-top">
    <div class="container-default w-container">
      <div
        class="tw-grid tw-grid-cols-1 tw-items-start tw-items-center tw-justify-start lg:tw-grid-cols-2 lg:tw-gap-x-12"
      >
        <div :class="{ 'lg:tw-order-2': vars.isReversedBool }">
          <template v-if="vars.videoUrl">
            <library-iframe :src="vars.videoUrl" :title="vars.titleText" />
          </template>
          <template v-else>
            <library-image :src="vars.backgroundImage" :alt="vars.titleText" />
          </template>
        </div>
        <div
          id="w-node-ffff3ff4-8ea6-b435-ae2d-a9fa7fde4af2-9e5c1e31"
          class="inner-container _560px _100-mbl _2-col-span-tablet max-lg:!tw-max-w-full"
          :class="{ 'lg:tw-order-1': vars.isReversedBool }"
        >
          <h2 class="heading-h2-size mg-bottom-12px" :style="`color: var(--${vars.titleColor}) !important;`">
            {{ vars.titleText }}
          </h2>

          <template v-if="descriptions.length > 0">
            <p
              v-for="(item, index) in descriptions"
              :key="index"
              :style="`color: var(--${vars.textColor}) !important;`"
            >
              {{ item.description }}
            </p>
          </template>

          <template v-if="vars.buttons1LabelText">
            <div class="gap-column-16px buttons-row">
              <div v-for="(button, index) in buttons" :key="`button-${index}`" class="max-md:tw-w-full">
                <nuxt-link
                  :to="button.link"
                  :external="true"
                  class="w-button"
                  :class="`${button.type} ${index === 0 ? 'button-row' : null}`"
                >
                  {{ button.label }}
                </nuxt-link>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'InfoBox',

  mixins: [ComponentMixin],

  computed: {
    buttons() {
      return this.groupedVariables.buttons;
    },

    descriptions() {
      return this.groupedVariables.descriptions;
    },
  },
});
</script>
