<template>
  <div
    class="section"
    :style="`background-color: var(--${vars.backgroundColor}) !important; color: var(--${vars.textColor});`"
  >
    <div class="container-default w-container">
      <div class="footer-top">
        <div
          id="w-node-eda65cc1-346b-d98c-3a87-0257e76bab25-e76bab22"
          data-w-id="eda65cc1-346b-d98c-3a87-0257e76bab25"
          class="_2-col-span-tablet"
          style="
            transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
              skew(0deg, 0deg);
            transform-style: preserve-3d;
            opacity: 1;
          "
        >
          <h2 class="display-2 font-size-30px-mbp color-neutral-100">
            {{ vars.titleText }}
          </h2>
        </div>
        <div
          id="w-node-eda65cc1-346b-d98c-3a87-0257e76bab2a-e76bab22"
          data-w-id="eda65cc1-346b-d98c-3a87-0257e76bab2a"
          class="width-100 _2-col-span-tablet"
          style="
            transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
              skew(0deg, 0deg);
            transform-style: preserve-3d;
            opacity: 1;
          "
        >
          <p class="color-neutral-400 mg-bottom-0">
            {{ vars.descriptionText }}
          </p>
          <div class="grid-2-columns _2-col-mbl gap-20px">
            <nuxt-link
              v-for="(item, index) in cards"
              :key="index"
              :to="item.link"
              class="footer-download-button w-inline-block"
            >
              <img :src="item.icon" loading="eager" width="50" height="50" alt="" />
              <div class="text-300 bold color-neutral-100">
                <span class="font-size-16px-mbp">{{ item.title }}</span>
              </div>
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'OutroSection',

  mixins: [ComponentMixin],

  computed: {
    cards() {
      return this.groupedVariables.cards;
    },
  },
});
</script>
