<template>
  <div class="top-section image-right career tw-min-h-[100svh]">
    <div class="container-default w-container">
      <div class="grid-2-columns _3fr---1fr">
        <div id="w-node-f76a71b9-f388-a812-ba7e-b0408ea0c59a-9e5c1e4e" class="inner-container _552px _100-mbl">
          <h1
            data-w-id="53108659-3125-55e1-a921-794ed4b17f2a"
            style="
              transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
                skew(0deg, 0deg);
              transform-style: preserve-3d;
              opacity: 1;
            "
            class="display-1 color-neutral-100 mg-bottom-32px"
          >
            {{ vars.titleText }}
          </h1>
          <p
            data-w-id="b98b16c8-e9dd-d039-8396-9d201b3a2ad5"
            style="
              transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
                skew(0deg, 0deg);
              transform-style: preserve-3d;
              opacity: 1;
            "
            class="color-neutral-400 mg-bottom-40px"
          >
            {{ vars.descriptionText }}
          </p>
          <div
            data-w-id="cc8185ab-ef5a-69bc-6b98-9c13e6fb01cd"
            style="
              transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
                skew(0deg, 0deg);
              transform-style: preserve-3d;
              opacity: 1;
            "
            class="buttons-row tw-mt-10"
          >
            <div v-for="(button, index) in buttons" :key="`button-${index}`" class="max-md:tw-w-full">
              <nuxt-link
                :to="button.link"
                :external="true"
                class="w-button tw-whitespace-nowrap"
                :class="`${button.type} ${index === 0 ? 'button-row' : null}`"
              >
                {{ button.label }}
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
      <div
        id="w-node-_5e110784-1fc0-16ce-de98-5d8a72c40693-9e5c1e4e"
        data-w-id="5e110784-1fc0-16ce-de98-5d8a72c40693"
        style="
          transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
            skew(0deg, 0deg);
          transform-style: preserve-3d;
          opacity: 1;
        "
        class="top-section-image-right---wrapper career-image"
      >
        <img
          :src="vars.photoImage"
          loading="eager"
          sizes="(max-width: 479px) 93vw, (max-width: 767px) 95vw, (max-width: 1439px) 86vw, 1238px"
          srcset="
            https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/644a6cebe40c043d913fe00a_careers-hero-image-right-sleek-webflow-ecommerce-template-p-500.png  500w,
            https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/644a6cebe40c043d913fe00a_careers-hero-image-right-sleek-webflow-ecommerce-template-p-800.png  800w,
            https://cdn.prod.website-files.com/644a6ceae40c04757d3fddbf/644a6cebe40c043d913fe00a_careers-hero-image-right-sleek-webflow-ecommerce-template.png       2452w
          "
          alt="Iceberg Digital Careers"
          class="image-28"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'CareerIntro',

  mixins: [ComponentMixin],

  computed: {
    buttons() {
      return this.groupedVariables.buttons;
    },
  },
});
</script>
