<template>
  <LibraryPopupsVideoPopup
    v-if="vars.isPopupBool && vars.videoUrl"
    :videoUrl="vars.videoUrl"
    v-model:is-visible="isVisible"
    :portrait="vars.isVerticalBool"
  />

  <section
    v-if="variants.v1 === variant"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
  >
    <MotionGroup preset="fadeVisible" :duration="600">
      <div
        class="tw-grid tw-max-w-xl tw-grid-cols-1 tw-gap-4 xl:tw-max-w-none xl:tw-grid-cols-12 xl:tw-gap-x-20 2xl:tw-gap-x-32"
      >
        <!-- Fotoğraf gösterimi -->
        <LibraryImage
          v-if="vars.photoImage && !vars.videoUrl"
          :src="vars.photoImage"
          :alt="vars.titleText"
          class="tw-w-full tw-self-center xl:tw-col-span-6"
          :w-ratio="vars.isVerticalBool ? 3 : 645"
          :h-ratio="vars.isVerticalBool ? 4 : 430"
          style="border-radius: var(--rounded)"
        />
        <!-- Photo or popup video logic -->
        <div
          v-if="vars.photoImage && vars.videoUrl && vars.isPopupBool"
          class="tw-relative tw-w-full tw-self-center tw-rounded-xl tw-object-cover xl:tw-col-span-6"
          :class="{ 'xl:tw-order-last': vars.isReversedBool }"
          @click="isVisible = true"
        >
          <!-- Content (image or popup) -->
          <LibraryImage
            :src="vars.photoImage"
            :alt="vars.titleText"
            class="tw-w-full tw-cursor-pointer"
            :w-ratio="vars.isVerticalBool ? 2 : 645"
            :h-ratio="vars.isVerticalBool ? 3 : 430"
            style="border-radius: var(--rounded)"
          />
          <!-- Play button for video -->
          <button class="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2">
            <svg width="80" height="80" viewBox="0 0 162 162" xmlns="http://www.w3.org/2000/svg">
              <circle cx="81" cy="81" r="81" fill="white" />
              <polygon points="65,45 115,81 65,117" fill="black" />
            </svg>
          </button>
        </div>

        <!-- Inline video logic -->
        <div
          v-if="!vars.isPopupBool && vars.videoUrl"
          class="tw-w-full tw-self-center tw-object-cover xl:tw-col-span-6"
        >
          <iframe
            v-if="!vars.isPopupBool && vars.videoUrl"
            class="tw-w-full tw-rounded-xl tw-object-cover"
            :src="vars.videoUrl"
            style="border-radius: var(--rounded)"
            frameborder="0"
            allowfullscreen
            :style="vars.isVerticalBool ? 'aspect-ratio: 9/13;' : 'aspect-ratio: 16/9;'"
          ></iframe>
        </div>

        <div
          class="tw-flex tw-flex-col tw-justify-center xl:tw-col-span-6"
          :class="{ 'xl:tw-order-first': vars.isReversedBool }"
        >
          <!-- Title section -->
          <div class="tw-mb-8 tw-mt-4 tw-flex tw-flex-col xl:tw-mb-10">
            <small v-if="vars.altTitleText"> {{ vars.altTitleText }}</small>
            <h2>{{ vars.titleText }}</h2>
          </div>
          <!-- Description and features section -->
          <div class="tw-flex tw-flex-col xl:tw-self-start">
            <div v-html="vars.descriptionHtml" class="ttw-flex-col tw-flex tw-gap-2"></div>
            <!-- Features list -->
            <div class="tw-mt-4 tw-grid tw-gap-x-10 tw-gap-y-2 tw-pt-5 sm:tw-mr-auto sm:tw-grid-cols-2">
              <div class="tw-flex tw-flex-col tw-gap-y-4">
                <div
                  v-if="featuresColumns1"
                  v-for="(feature, index) in featuresColumns1"
                  :key="index"
                  class="tw-flex tw-items-center tw-gap-3"
                >
                  <svg
                    class="tw-shrink-0"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.5501 18L3.8501 12.3L5.2751 10.875L9.5501 15.15L18.7251 5.97498L20.1501 7.39998L9.5501 18Z"
                      fill="var(--c-primary)"
                    />
                  </svg>
                  <span>{{ feature.title }}</span>
                </div>
              </div>
              <div class="tw-flex tw-flex-col tw-gap-y-4">
                <div
                  v-if="featuresColumns2"
                  v-for="(feature, index) in featuresColumns2"
                  :key="index"
                  class="tw-flex tw-items-center tw-gap-3"
                >
                  <svg
                    class="tw-shrink-0"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.5501 18L3.8501 12.3L5.2751 10.875L9.5501 15.15L18.7251 5.97498L20.1501 7.39998L9.5501 18Z"
                      fill="var(--c-primary)"
                    />
                  </svg>
                  <span>{{ feature.title }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MotionGroup>
  </section>

  <section
    v-if="variants.v2 === variant"
    class="n-section-tertiary"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}; ${vars.isReversedBool ? 'padding-left: calc((100vw - 100%) / 2) !important;' : 'padding-right: calc((100vw - 100%) / 2) !important;'}`"
  >
    <MotionGroup preset="slideVisibleBottom" :duration="600">
      <div
        class="tw-grid tw-max-w-xl tw-grid-cols-1 tw-gap-4 xl:tw-max-w-none xl:tw-grid-cols-12 xl:tw-gap-x-20 2xl:tw-gap-x-32"
      >
        <!-- Fotoğraf gösterimi -->
        <div
          class="tw-w-full tw-self-center tw-overflow-hidden tw-rounded-[var(--rounded)] xl:tw-col-span-6"
          v-if="vars.photoImage && !vars.videoUrl"
          :class="{
            'lg:tw-rounded-none lg:tw-rounded-br-[var(--rounded)] lg:tw-rounded-tr-[var(--rounded)]':
              !vars.isReversedBool,
            'lg:tw-rounded-none lg:tw-rounded-bl-[var(--rounded)] lg:tw-rounded-tl-[var(--rounded)]':
              vars.isReversedBool,
          }"
        >
          <LibraryImage
            :src="vars.photoImage"
            :alt="vars.titleText"
            class="tw-w-full tw-self-center"
            :w-ratio="vars.isVerticalBool ? 3 : 645"
            :h-ratio="vars.isVerticalBool ? 4 : 430"
          />
        </div>

        <!-- Photo or popup video logic -->
        <div
          v-if="vars.photoImage && vars.videoUrl && vars.isPopupBool"
          class="tw-relative tw-w-full tw-self-center tw-overflow-hidden tw-rounded-xl tw-object-cover xl:tw-col-span-6"
          :class="{
            'xl:tw-order-last': vars.isReversedBool,
            'lg:tw-rounded-none lg:tw-rounded-br-[var(--rounded)] lg:tw-rounded-tr-[var(--rounded)]':
              !vars.isReversedBool,
            'lg:tw-rounded-none lg:tw-rounded-bl-[var(--rounded)] lg:tw-rounded-tl-[var(--rounded)]':
              vars.isReversedBool,
          }"
          @click="isVisible = true"
        >
          <!-- Content (image or popup) -->
          <LibraryImage
            :src="vars.photoImage"
            :alt="vars.titleText"
            class="tw-w-full tw-cursor-pointer"
            :w-ratio="vars.isVerticalBool ? 2 : 645"
            :h-ratio="vars.isVerticalBool ? 3 : 430"
          />
          <!-- Play button for video -->
          <button class="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2">
            <svg width="80" height="80" viewBox="0 0 162 162" xmlns="http://www.w3.org/2000/svg">
              <circle cx="81" cy="81" r="81" fill="white" />
              <polygon points="65,45 115,81 65,117" fill="black" />
            </svg>
          </button>
        </div>

        <!-- Inline video logic -->
        <div
          v-if="!vars.isPopupBool && vars.videoUrl"
          class="tw-w-full tw-self-center tw-object-cover xl:tw-col-span-6"
        >
          <iframe
            v-if="!vars.isPopupBool && vars.videoUrl"
            class="tw-w-full tw-rounded-[var(--rounded)] tw-object-cover"
            :src="vars.videoUrl"
            :class="{
              'lg:tw-rounded-none lg:tw-rounded-br-[var(--rounded)] lg:tw-rounded-tr-[var(--rounded)]':
                !vars.isReversedBool,
              'lg:tw-rounded-none lg:tw-rounded-bl-[var(--rounded)] lg:tw-rounded-tl-[var(--rounded)]':
                vars.isReversedBool,
            }"
            frameborder="0"
            allowfullscreen
            :style="vars.isVerticalBool ? 'aspect-ratio: 9/13;' : 'aspect-ratio: 16/11;'"
          ></iframe>
        </div>

        <div
          class="tw-flex tw-flex-col tw-justify-center xl:tw-col-span-6"
          :class="{ 'xl:tw-order-first': vars.isReversedBool }"
        >
          <!-- Title section -->
          <div class="tw-mb-8 tw-mt-4 tw-flex tw-flex-col xl:tw-mb-10">
            <NuxtImg v-if="vars.logoImage" :src="vars.logoImage" class="tw-mb-7 tw-w-16" />
            <h2>{{ vars.titleText }}</h2>
          </div>
          <!-- Description and features section -->
          <div class="tw-flex tw-flex-col xl:tw-self-start">
            <div v-html="vars.descriptionHtml" class="ttw-flex-col tw-flex tw-gap-2"></div>
            <!-- Features list -->
            <div class="tw-mt-4 tw-grid tw-gap-x-10 tw-gap-y-2 tw-pt-5 sm:tw-mr-auto sm:tw-grid-cols-2">
              <div class="tw-flex tw-flex-col tw-gap-y-4">
                <div
                  v-if="featuresColumns1"
                  v-for="(feature, index) in featuresColumns1"
                  :key="index"
                  class="tw-flex tw-items-center tw-gap-3"
                >
                  <svg
                    class="tw-shrink-0"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.5501 18L3.8501 12.3L5.2751 10.875L9.5501 15.15L18.7251 5.97498L20.1501 7.39998L9.5501 18Z"
                      fill="var(--c-primary)"
                    />
                  </svg>
                  <span>{{ feature.title }}</span>
                </div>
              </div>
              <div class="tw-flex tw-flex-col tw-gap-y-4">
                <div
                  v-if="featuresColumns2"
                  v-for="(feature, index) in featuresColumns2"
                  :key="index"
                  class="tw-flex tw-items-center tw-gap-3"
                >
                  <svg
                    class="tw-shrink-0"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.5501 18L3.8501 12.3L5.2751 10.875L9.5501 15.15L18.7251 5.97498L20.1501 7.39998L9.5501 18Z"
                      fill="var(--c-primary)"
                    />
                  </svg>
                  <span>{{ feature.title }}</span>
                </div>
              </div>
            </div>
            <div>
              <nuxt-link
                v-if="vars.buttonLinkUrl"
                :to="vars.buttonLinkUrl"
                :class="`n-${vars.buttonTypeText} n-btn tw-mt-10`"
              >
                <div class="n-btn-container">
                  <div class="n-btn-group">
                    <div class="n-btn-t1">{{ vars.buttonLabelText }}</div>
                    <div class="n-btn-t2">{{ vars.buttonLabelText }}</div>
                  </div>
                </div>
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </MotionGroup>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'WhyBox',

  mixins: [ComponentMixin],

  data() {
    return {
      isVisible: false,
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  computed: {
    featuresColumns1() {
      return this.groupedVariables.column_1_features;
    },
    featuresColumns2() {
      return this.groupedVariables.column_2_features;
    },
  },
});
</script>

<style scoped>
@media (max-width: 1280px) {
  section.n-section-tertiary {
    padding-top: var(--tertiary_section_padding);
    padding-bottom: var(--tertiary_section_padding);
    padding-left: calc((100vw - 100%) / 2) !important;
    padding-right: calc((100vw - 100%) / 2) !important;
  }
}
</style>
