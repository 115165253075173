<template>
  <!-- Variant 1 -->
  <section
    v-if="variants.v1 === variant"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor});color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
  >
    <div
      class="tw-mb-10 tw-flex tw-flex-col tw-items-start tw-self-start tw-text-start md:tw-items-center md:tw-self-center md:tw-text-center lg:tw-mb-16"
    >
      <small v-if="vars.altTitleText"> {{ vars.altTitleText }}</small>
      <h2>{{ vars.titleText }}</h2>
    </div>
    <div
      class="tw-flex tw-w-full tw-flex-col tw-gap-10 tw-gap-y-10 tw-text-center !tw-text-white md:tw-gap-y-20 lg:tw-flex-row"
    >
      <div
        v-for="(member, index) in members"
        :key="index"
        class="no-hover shine-effect tw-group tw-relative tw-basis-1/3 tw-overflow-hidden tw-transition-all tw-duration-500 md:hover:tw-scale-105"
      >
        <LibraryImage :w-ratio="14" :h-ratio="9" :src="member.member_photo" style="border-radius: var(--rounded)" />
        <div
          class="shine tw-absolute tw-inset-0 tw-bg-black tw-opacity-0 tw-transition-opacity tw-duration-500 group-hover:tw-opacity-50"
          style="border-radius: var(--rounded)"
        ></div>
        <div
          class="agent-info-v1 tw-pointer-events-none tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-z-20 tw-flex tw-translate-y-full tw-transform tw-flex-col tw-items-center tw-gap-5 tw-overflow-hidden tw-p-4 tw-text-center tw-opacity-0 tw-transition-all tw-duration-500 group-hover:tw-pointer-events-auto group-hover:tw-translate-y-0 group-hover:tw-opacity-100"
        >
          <div class="tw-flex tw-flex-col">
            <h6>{{ member.member_name }}</h6>
            <p class="n-semibold">{{ member.member_role }}</p>
          </div>
          <button class="n-link" @click="toggleTeamMemberDetailPopup(member, true)">VIEW MORE DETAILS</button>
        </div>
      </div>
    </div>
    <nuxt-link
      :external="true"
      :class="`n-${vars.buttonTypeText} n-btn tw-mt-10 tw-self-start md:tw-self-center`"
      :to="vars.buttonLinkUrl"
      v-if="vars.buttonLinkUrl"
    >
      <div class="n-btn-container">
        <div class="n-btn-group">
          <div class="n-btn-t1">{{ vars.buttonLabelText }}</div>
          <div class="n-btn-t2">{{ vars.buttonLabelText }}</div>
        </div>
      </div>
    </nuxt-link>

    <MotionGroup :preset="vars.animationText ? vars.animationText : 'popVisible'" :duration="400">
      <popup v-if="selectedMember && popupConfig.isActive" :config="popupConfig" custom-width="90%">
        <template #content>
          <div class="tw-grid tw-h-full tw-w-full tw-grid-cols-12 tw-gap-y-4 tw-py-4 lg:tw-gap-x-6">
            <div class="tw-col-span-12 tw-flex tw-w-full tw-flex-col tw-justify-center lg:tw-col-span-7">
              <h2>{{ selectedMember.member_name }}</h2>
              <p class="n-semibold tw-mt-2">{{ selectedMember.member_role }}</p>
              <div class="tw-py-8">
                <div v-if="selectedMember.member_email" class="tw-flex tw-items-center tw-gap-1 tw-pb-3">
                  <nuxt-link :to="`mailto:${selectedMember.member_email}`" class="n-link">
                    <p class="n-semibold">{{ selectedMember.member_email }}</p>
                  </nuxt-link>
                </div>
                <div v-if="selectedMember.member_phone" class="tw-flex tw-items-center tw-gap-1">
                  <nuxt-link :to="`tel:${selectedMember.member_phone}`" class="n-link">
                    <p class="n-semibold">{{ selectedMember.member_phone }}</p>
                  </nuxt-link>
                </div>
              </div>
              <div class="tw-flex tw-flex-col tw-gap-3" v-html="selectedMember.popup_descriptions"></div>
              <nuxt-link
                :external="true"
                :class="`n-${selectedMember.popup_button_type} n-btn tw-mt-12 tw-max-w-fit`"
                :to="selectedMember.popup_button_link"
                v-if="selectedMember.popup_button_link"
              >
                <div class="n-btn-container">
                  <div class="n-btn-group">
                    <div class="n-btn-t1">{{ selectedMember.popup_button_label }}</div>
                    <div class="n-btn-t2">{{ selectedMember.popup_button_label }}</div>
                  </div>
                </div>
              </nuxt-link>
            </div>
            <div
              class="tw-order-first tw-col-span-12 tw-flex tw-items-center tw-justify-center lg:tw-order-none lg:tw-col-span-5"
            >
              <div style="position: relative; height: 100%; width: 100%">
                <iframe
                  :src="selectedMember?.popup_video"
                  width="100%"
                  height="100%"
                  style="position: relative; top: 0; left: 0; aspect-ratio: 16/9"
                />
              </div>
            </div>
          </div>
        </template>
      </popup>
    </MotionGroup>

    <library-popups-video-popup
      v-if="selectedMember?.popup_video"
      :videoUrl="selectedMember?.popup_video"
      v-model:is-visible="isVisible"
    />
  </section>

  <!-- Variant 2 -->
  <section
    v-if="variants.v2 === variant"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor});color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
  >
    <div class="tw-flex tw-flex-col tw-items-center tw-self-center tw-text-center lg:tw-mb-6">
      <h2>{{ vars.titleText }}</h2>
    </div>
    <div class="tw-w-full !tw-text-white">
      <Swiper
        :modules="[SwiperPagination]"
        @slideChange="onSlideChange"
        @swiper="onSwiper"
        :pagination="false"
        :loop="true"
        :slides-per-view="1"
        :space-between="32"
        effect="fade"
        :clickable="true"
        :breakpoints="{
          320: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
          1536: {
            slidesPerView: 3,
            spaceBetween: 60,
          },
        }"
        class="tw-w-full"
      >
        <SwiperSlide v-for="(member, index) in members" :key="index">
          <nuxt-link class="n-link" :to="member.member_link" :external="true">
            <div
              class="no-hover shine-effect tw-group tw-relative tw-basis-1/3 tw-overflow-hidden tw-transition-all tw-duration-500"
            >
              <LibraryImage
                :w-ratio="9"
                :h-ratio="12"
                :src="member.member_photo"
                style="border-radius: var(--rounded)"
              />
              <div
                class="shine tw-absolute tw-inset-0 tw-bg-black tw-opacity-0 tw-transition-opacity tw-duration-500 group-hover:tw-opacity-20"
                style="border-radius: var(--rounded)"
              ></div>
              <div
                class="agent-info-v2 tw-pointer-events-none tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-z-20 tw-flex tw-translate-y-full tw-transform tw-flex-col tw-items-center tw-gap-5 tw-overflow-hidden tw-p-4 tw-text-center tw-opacity-0 tw-transition-all tw-duration-500 group-hover:tw-pointer-events-auto group-hover:-tw-translate-y-16 group-hover:tw-opacity-100"
              >
                <div class="tw-flex tw-flex-col tw-gap-6">
                  <h6>{{ member.member_name }}</h6>
                  <p class="n-semibold">{{ member.member_role }}</p>
                </div>
              </div>
            </div>
          </nuxt-link>
        </SwiperSlide>
      </Swiper>
      <div>
        <LibrarySliderPagination
          :swiper="swiper"
          variant="v5"
          :can-paginate-back="canPaginateBack"
          :can-paginate-next="canPaginateNext"
        />
      </div>
    </div>
    <nuxt-link
      :external="true"
      :class="`n-${vars.buttonTypeText} n-btn tw-mt-8 tw-self-center`"
      :to="vars.buttonLinkUrl"
      v-if="vars.buttonLinkUrl"
    >
      <div class="n-btn-container">
        <div class="n-btn-group">
          <div class="n-btn-t1">{{ vars.buttonLabelText }}</div>
          <div class="n-btn-t2">{{ vars.buttonLabelText }}</div>
        </div>
      </div>
    </nuxt-link>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import Popup from '~/components/common/popup/Popup.vue';
export default defineNuxtComponent({
  components: { Popup },

  mixins: [ComponentMixin],

  data() {
    return {
      selectedMember: null,
      swiper: null,
      canPaginateBack: true,
      canPaginateNext: true,
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
      popupConfig: {
        isActive: false,
      },
      isVisible: false,
    };
  },

  computed: {
    members() {
      return this.groupedVariables.members;
    },
  },

  methods: {
    toggleTeamMemberDetailPopup(member = null, value = false) {
      if (member.popup_title) {
        this.selectedMember = member;
        this.popupConfig.isActive = value;
      }
    },

    onSwiper(swiper) {
      this.swiper = swiper;
    },

    onSlideChange() {
      if (!this.swiper) return;
      const isAtStart = this.swiper.isBeginning;
      const isAtEnd = this.swiper.isEnd;
      if (isAtStart) this.canPaginateBack = false;
      else this.canPaginateBack = true;
      if (isAtEnd) this.canPaginateNext = false;
      else this.canPaginateNext = true;
    },
  },

  mounted() {
    this.onSlideChange();
  },
});
</script>

<style scoped>
@media (hover: none) {
  .no-hover .shine-effect {
    filter: none;
  }

  .no-hover .shine {
    opacity: 30%;
  }

  .no-hover .agent-info-v2 {
    opacity: 100%;
    pointer-events: auto;
    transform: translateY(-4rem);
  }
  .no-hover .agent-info-v1 {
    opacity: 100%;
    pointer-events: auto;
    transform: translateY(0px);
  }
}
</style>
