<template>
  <section v-if="variants.v1 === variant" class="n-section-primary">
    <div class="tw-w-full">
      <h2 class="tw-text-left">Search E-Guides</h2>
      <search-box
        v-model="keyword"
        :search-result-list="[]"
        placeholder="Search"
        :search-method="onSearched"
        version="v2"
      />
    </div>

    <div class="tw-mb-8 tw-flex tw-w-full tw-flex-wrap tw-gap-4">
      <button
        v-for="category in categories"
        :key="category.value"
        @click="handleCategoryClick(category.value)"
        class="n-outlined n-btn tw-mt-10 !tw-w-fit tw-self-start md:tw-self-center"
        :class="{
          'select-button active': selectedCategory === category.value,
        }"
      >
        <div class="n-btn-container">
          <div class="n-btn-group">
            <div class="n-btn-t1">{{ category.label }}</div>
            <div class="n-btn-t2">{{ category.label }}</div>
          </div>
        </div>
      </button>
    </div>

    <div class="tw-flex tw-w-full tw-flex-col tw-gap-x-20 xl:tw-flex-row">
      <div class="tw-grid tw-w-full tw-grid-cols-1 tw-gap-6 md:tw-grid-cols-2 xl:tw-grid-cols-4">
        <div v-for="(guide, index) in filteredGuides" :key="index">
          <nuxt-link class="n-link" :to="guide.guide_link">
            <guide-card
              :guide="guide"
              :index="index"
              :variant="variants.v1"
              :component="component"
              @click="toggleGuidePopup(guide, true)"
            />
          </nuxt-link>
        </div>
      </div>

      <nuxt-link
        v-if="vars.buttonLinkUrl"
        :external="true"
        :class="`n-${vars.buttonTypeText} n-btn tw-mt-8 tw-self-center xl:!tw-hidden`"
        :to="vars.buttonLinkUrl"
      >
        <div class="n-btn-container">
          <div class="n-btn-group">
            <div class="n-btn-t1">{{ vars.buttonLabelText }}</div>
            <div class="n-btn-t2">{{ vars.buttonLabelText }}</div>
          </div>
        </div>
      </nuxt-link>
    </div>

    <popup v-if="selectedGuide && popupConfig.isActive" :config="popupConfig" custom-width="90%">
      <template #content>
        <div class="tw-w-full">
          <div
            class="tw-mx-auto tw-flex tw-w-full tw-flex-col-reverse md:tw-w-[80%] lg:tw-w-full lg:tw-flex-row"
            style="border-radius: var(--rounded)"
          >
            <div class="tw-flex tw-w-full tw-flex-col tw-p-6 md:tw-p-10 xl:tw-px-20">
              <small v-if="vars.altTitleText">{{ selectedGuide.popup_alt_title }}</small>
              <h2 class="tw-mb-5">{{ selectedGuide.popup_title }}</h2>
              <library-form
                v-if="selectedGuide.guide"
                :form="parseFormV2(selectedGuide.guide)"
                version="v2"
                :component="component"
                :is-form-name-visible="false"
              />
            </div>
            <div class="tw-mt-5 tw-w-full lg:tw-max-w-[465px]">
              <LibraryImage
                :w-ratio="1"
                :h-ratio="1.45"
                :src="selectedGuide.popup_cover"
                style="border-radius: var(--rounded)"
              />
            </div>
          </div>
        </div>
      </template>
    </popup>
  </section>

  <section
    v-if="variant === variants.v2"
    class="n-section-primary tw-overflow-hidden"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
  >
    <div class="tw-grid tw-w-full tw-grid-cols-12 tw-gap-4">
      <!-- Ana İçerik Kolonu -->
      <div class="tw-col-span-12 tw-w-full lg:tw-col-span-7 xl:tw-col-span-8">
        <!-- Search Bölümü -->
        <div class="tw-w-full">
          <h2 class="tw-text-left">Search E-Guides</h2>
          <search-box
            v-model="keyword"
            :search-result-list="[]"
            placeholder="Search"
            :search-method="onSearched"
            version="v2"
          />
        </div>

        <!-- Guide Cards Grid -->
        <div class="tw-flex tw-w-full tw-flex-col tw-gap-x-20 xl:tw-flex-row">
          <div class="tw-grid tw-w-full tw-grid-cols-1 tw-gap-6 md:tw-grid-cols-2 xl:tw-grid-cols-4">
            <div v-for="(guide, index) in filteredGuides" :key="index">
              <nuxt-link class="n-link" :to="guide.guide_link">
                <guide-card
                  :guide="guide"
                  :index="index"
                  :variant="variants.v1"
                  :component="component"
                  @click="toggleGuidePopup(guide, true)"
                />
              </nuxt-link>
            </div>
          </div>
        </div>

        <nuxt-link
          v-if="vars.buttonLinkUrl"
          :external="true"
          :class="`n-${vars.buttonTypeText} n-btn tw-mt-8 tw-self-center xl:!tw-hidden`"
          :to="vars.buttonLinkUrl"
        >
          <div class="n-btn-container">
            <div class="n-btn-group">
              <div class="n-btn-t1">{{ vars.buttonLabelText }}</div>
              <div class="n-btn-t2">{{ vars.buttonLabelText }}</div>
            </div>
          </div>
        </nuxt-link>
      </div>

      <!-- Sidebar Kolonu -->
      <div
        v-if="sidebarComponents && sidebarComponents.length > 0"
        class="tw-col-span-12 lg:tw-col-span-5 xl:tw-col-span-4"
      >
        <div class="sidebar tw-flex tw-flex-col tw-gap-6">
          <theme6-available-components-for-sidebar
            key="blogs-page-sidebar"
            key-prefix="blogs-page-sidebar"
            :specific-components="sidebarComponents"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import GuideCard from './GuideCard.vue';
import Popup from '~/components/common/popup/Popup.vue';
import { ComponentMixin } from '~/mixins/component.mixin';
import { useWebsiteStore } from '~/units/website/store';
import SearchBox from '~/components/common/SearchBox.vue';

export default defineNuxtComponent({
  name: 'OurGuide',

  mixins: [ComponentMixin],

  components: {
    SearchBox,
    GuideCard,
    Popup,
  },

  data() {
    return {
      swiper: null,
      form: null,
      canPaginateBack: true,
      canPaginateNext: true,
      selectedGuide: null,
      popupConfig: {
        isActive: false,
      },
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
      categories: [
        { label: 'All', value: null },
        { label: 'Sellers', value: 'sellers' },
        { label: 'Buyers', value: 'buyers' },
        { label: 'Landlords', value: 'landlords' },
        { label: 'Tenants', value: 'tenants' },
      ],
      selectedCategory: null,
      keyword: '',
    };
  },

  /* created() {
    this.form = this.parseFormV2('guide-form');
  }, */

  mounted() {
    this.onSlideChange();
  },

  computed: {
    ...mapState(useWebsiteStore, ['sidebarComponents']),

    guides() {
      return this.groupedVariables.guides;
    },

    filteredGuides() {
      let results = this.guides;

      if (this.selectedCategory) {
        results = results.filter((g) => g.category === this.selectedCategory);
      }

      if (this.keyword && this.keyword.trim().length > 0) {
        const lowerKeyword = this.keyword.trim().toLowerCase();
        results = results.filter((g) => g.title.toLowerCase().includes(lowerKeyword));
      }

      return results;
    },
  },

  methods: {
    onSearched(searchTerm: string) {},

    handleCategoryClick(category: string) {
      this.selectedCategory = category;
    },

    toggleGuidePopup(guide = null, value = false) {
      if (guide?.popup_title) {
        this.selectedGuide = guide;
        this.popupConfig.isActive = value;
      }
    },

    onSwiper(swiper) {
      this.swiper = swiper;
    },

    onSlideChange() {
      if (this.variant === this.variants.v1) {
        if (!this.swiper) return;
        const isAtStart = this.swiper.isBeginning;
        const isAtEnd = this.swiper.isEnd;
        if (isAtStart) this.canPaginateBack = false;
        else this.canPaginateBack = true;
        if (isAtEnd) this.canPaginateNext = false;
        else this.canPaginateNext = true;
      } else if (this.variant === this.variants.v2) {
        if (!this.swiper) return;
        this.canPaginateBack = true;
        this.canPaginateNext = true;
      }
    },
  },
});
</script>
