<template>
  <div class="top-section bg-v2" :style="`background-color: var(--${vars.backgroundColor}) !important;`">
    <div class="container-default w-container">
      <div id="w-node-d48ca5d5-9881-ca92-5d77-63d9eb36f97d-9e5c1f73" class="text-center mg-bottom-64px">
        <div
          data-w-id="df988cd3-7143-e3e8-5186-cecdafbbd98d"
          style="
            -webkit-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            -moz-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            -ms-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
          "
          class="inner-container _668px center"
        >
          <h1 class="display-1 color-neutral-100" :style="`color: var(--${vars.titleColor}) !important;`">
            {{ vars.titleText }}
          </h1>
        </div>
        <div
          data-w-id="082a5d4c-7894-9f2d-1378-636eb3ac0e5c"
          style="
            -webkit-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            -moz-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            -ms-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
          "
          class="inner-container _560px center"
        >
          <p class="color-neutral-400 center-text tw-mb-1" :style="`color: var(--${vars.textColor}) !important;`">
            {{ vars.descriptionText }}
          </p>
        </div>
        <div
          class="tw-mt-4"
          data-w-id="d48ca5d5-9881-ca92-5d77-63d9eb36f98b"
          style="
            -webkit-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            -moz-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            -ms-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
          "
          v-if="vars.buttons1LabelText"
        >
          <div v-for="(button, index) in buttons" :key="`button-${index}`" class="max-md:tw-w-full">
            <nuxt-link
              :to="button.link"
              :external="true"
              class="w-button"
              :class="`${button.type} ${index === 0 ? 'button-row' : null}`"
            >
              {{ button.label }}
            </nuxt-link>
          </div>
        </div>
      </div>
      <div
        data-w-id="78d65480-b617-a626-d52f-3bb5af6d10e4"
        class="top-section---bottom-interfaces-container"
        :class="`${vars.backgroundSecondaryImage ? '' : 'tw-justify-center'}`"
      >
        <template v-if="vars.backgroundVideoUrl">
          <library-iframe :src="vars.backgroundVideoUrl" :title="vars.titleText"></library-iframe>
        </template>

        <template v-else>
          <img
            :src="vars.backgroundImage"
            loading="eager"
            :alt="vars.titleText"
            class="top-section---large-interface-bottom"
          />
          <img
            :src="vars.backgroundSecondaryImage"
            loading="eager"
            :alt="vars.titleText"
            class="top-section---small-interface-bottom"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'HeroSection',

  mixins: [ComponentMixin],

  computed: {
    descriptions() {
      return this.groupedVariables.descriptions;
    },

    buttons() {
      return this.groupedVariables.buttons;
    },
  },
});
</script>

<style scoped>
.top-section {
  background-color: white;
}
</style>
