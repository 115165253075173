<template>
  <div
    id="faqs"
    class="section bg-neutral-100"
    :style="`background-color: var(--${vars.backgroundColor}) !important; `"
  >
    <div class="container-default w-container">
      <div data-w-id="8b07a817-5ff6-14ef-a7c1-38188f2236cb" class="text-center mg-bottom-32px">
        <div class="inner-container _760px center">
          <h2 class="display-2" :style="`color: var(--${vars.titleColor}) !important;`">{{ vars.titleText }}</h2>
          <p
            v-for="(item, index) in descriptions"
            :key="index"
            class="center-text tw-mb-1"
            :style="`color: var(--${vars.textColor}) !important;`"
          >
            {{ item.description }}
          </p>
        </div>
      </div>
      <div class="inner-container _856px center">
        <div data-w-id="8b07a817-5ff6-14ef-a7c1-38188f2236d2" class="w-layout-grid grid-1-column">
          <div
            v-for="(faq, index) in faqs"
            :key="index"
            data-w-id="8b07a817-5ff6-14ef-a7c1-38188f2236d3"
            class="accordion-item-wrapper"
          >
            <div class="accordion-side">
              <div class="accordion-btn">
                <div class="accordion-btn-line vertical"></div>
                <div class="accordion-btn-line horizontal"></div>
              </div>
            </div>
            <div class="accordion-content-wrapper mg-top-8px">
              <div class="accordion-header">
                <h3 class="mg-bottom-0" :style="`color: var(--${vars.textColor}) !important;`">
                  <strong>{{ faq.title }}</strong>
                </h3>
              </div>
              <div class="acordion-body">
                <div class="accordion-spacer _10px"></div>
                <p class="mg-bottom-0" :style="`color: var(--${vars.textColor}) !important;`">
                  {{ faq.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'FAQs',

  mixins: [ComponentMixin],

  computed: {
    descriptions() {
      return this.groupedVariables.descriptions;
    },

    faqs() {
      return this.groupedVariables.faqs;
    },
  },
});
</script>
